import React, { useState, useEffect} from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './TooltipStyles.css';
import DownloadVideo from './DownloadVideo';

function DownloadButton({  isCheckAll,  ImgUri, setDownloadImages,
    downloadImages,  wo,  setIsCheckAll, isCheck, setIsCheck,  totalCount, createDialogBox,closeDialogBox,accessToken}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentDownloadList, setCurrentDownloadList] = useState([]);
  // const [downloadCount, setDownloadCount] = useState(0);
  // const downloadCountRef = useRef(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [filesDownloaded, setFilesDownloaded] = useState(0);

  useEffect(() => {
    let updatedDownloadList = downloadImages;
    setCurrentDownloadList(updatedDownloadList);
  }, [isCheckAll, ImgUri, downloadImages]);

  const handleDownload = async () => {
    setIsDownloading(true);
    // setDownloadCount(0);
    // downloadCountRef.current = 0;
    const filenames = new Set(); 

    // const updateDownloadCount = () => {
    //   downloadCountRef.current += 1;
    //   setDownloadCount(downloadCountRef.current);
    // };

    const downloadFileWithBrowser = async (fileurl, downloadFileName, downloadFileNameExtension) => {
      const a = document.createElement("a");
      a.href = fileurl;
      a.download = `${downloadFileName}.${downloadFileNameExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // updateDownloadCount();
    };

    const downloadFile = async (fileurl, downloadFileName, downloadFileNameExtension) => {
      try {
        const response = await fetch(fileurl);
        const blob = await response.blob();
        const a = document.createElement("a");
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `${downloadFileName}.${downloadFileNameExtension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        // updateDownloadCount();        
      } catch (error) {
        console.error(`Error downloading ${downloadFileName}.${downloadFileNameExtension}:`, error);
      }
    };

if (currentDownloadList.length <= 10) {
  const DownloadableExtensions = ["jpg","jpeg","png","heif","heic"];
  const videoExtensions = ["mp4","avi","hevc","wmv","mov"];
  // let videoStorageSasToken = await fetchVideoSASToken(accessToken);  

    // currentDownloadList.forEach(async (item, index) => {
    for (const [index, item] of currentDownloadList.entries()) {
    const [fileurl, downloadFileName, downloadFileNameExtension] = item.split("$");
    if (DownloadableExtensions.includes(downloadFileNameExtension.toLowerCase())) {
      try {      
        //Download with fetch api
        setTimeout(async () => {
        downloadFile(fileurl, downloadFileName, downloadFileNameExtension);
      }, index * 500);
      } catch (error) {
        console.log("Error downloading file:", error);
      }
    }
    else if(videoExtensions.includes(downloadFileNameExtension.toLowerCase())) {
      // setTimeout(async () => {
      //   downloadFileWithBrowser(fileurl + videoStorageSasToken, downloadFileName, downloadFileNameExtension);
      // }, index * 3000);
      console.log("preUrl:" + fileurl);
      await DownloadVideo(downloadFileName,downloadFileNameExtension, fileurl,setDownloadProgress);
    }
    else {
      setTimeout(async () => {
      downloadFileWithBrowser(fileurl, downloadFileName, downloadFileNameExtension);
      }, index * 3000);       
    }
  // });
  setFilesDownloaded(index+1);
    }
} else {
  // createDialogBox("Download",
  //   "Your download is in progress. Please note that for larger files, the process may take longer depending on your internet speed. Please be patient and kindly refrain from refreshing the page.",
  //   [],closeDialogBox);
  let zip = new JSZip();
  let chunkSizeMB = 1024;
  let currentChunkSize = 0;
  let chunkIndex = 0;
  const excludedExtensions = ["mp4","avi","hevc","wmv","mov","pdf"];

  for (let index = 0; index < currentDownloadList.length; index++) {
    const [fileurl, downloadFileName, downloadFileNameExtension] = currentDownloadList[index].split("$");
    
    let uniqueName = downloadFileName;
    let counter = 1;
    while (filenames.has(uniqueName + "." + downloadFileNameExtension)) {
      uniqueName = `${downloadFileName}(${counter})`;
      counter++;
    }
    filenames.add(uniqueName + "." + downloadFileNameExtension);
    
    if (!excludedExtensions.includes(downloadFileNameExtension.toLowerCase())){          
    try {
          const response = await fetch(fileurl);
          const blob = await response.blob();
          const fileSizeMB = blob.size / (1024 * 1024);

          zip.file(`${uniqueName}.${downloadFileNameExtension}`, blob);
          currentChunkSize += fileSizeMB;
          // updateDownloadCount();
                         
        if (currentChunkSize + fileSizeMB > chunkSizeMB) {
          const content = await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: { level: 6 },
            streamFiles: true,
          });
          saveAs(content, `WorkOrder_${wo}_part${chunkIndex}.zip`);
          chunkIndex++;
          currentChunkSize = 0;
          zip = new JSZip();
        }
      }
    catch (error) {
        console.error("Error downloading file:", error);            
        // createDialogBox("Download Failed", "Download Failed : " + error,[],closeDialogBox);
      }                  
    } else {          
    try {  
      if (excludedExtensions.includes(downloadFileNameExtension.toLowerCase())){
        await DownloadVideo(downloadFileName,downloadFileNameExtension,fileurl,setDownloadProgress);
      }
      else {         
        const response = await fetch(fileurl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${downloadFileName}.${downloadFileNameExtension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        // updateDownloadCount();
      }
                    
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } 
    setFilesDownloaded(index+1);
  }
  const content = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: { level: 6 },
    streamFiles: true,
  });
  saveAs(content, `WorkOrder_${wo}_part${chunkIndex}.zip`);   
}
setIsDownloading(false);
setIsCheckAll(false);
setIsCheck([]);
setCurrentDownloadList([]);
// setDownloadCount(0);
setDownloadImages([]);
};

  const buttonText = currentDownloadList.length > 10 ? "Download Zip" : "Download";
  const listLength = currentDownloadList.length;
  // const tippymsg = "Your download is underway. Files downloaded : ("+ {filesDownloaded} + "/" + {listLength} + ").";
  const tippymsg = `Your download is underway. Attempting download: (${filesDownloaded}/${listLength}).`;


  return (
    <>
    <Tippy content={
        <>
          <strong>Note:</strong> The download time may vary based on your internet connection 
          speed, specially for larger files. Please avoid refreshing the page while waiting.
        </>
      } placement="bottom" theme="custom">
    <button
      className={`btn-download ${isDownloading ? "is-downloading" : ""}`}
      onClick={handleDownload}
      disabled={isDownloading || !currentDownloadList.length || isCheck === 0}
    >
      {/* {isDownloading
        ? `Downloading...(${Math.round((downloadCount / totalCount) * 100)}%)`
        : buttonText} */}

        {/* {isDownloading ? (
                <Tippy content = "Your download is underway. Larger files may take longer. Please wait." placement="bottom" theme="custom">
                    <span>Downloading...({Math.round((downloadCount / totalCount) * 100)}%)</span>
                </Tippy>
            ) : (
               buttonText
            )} */}
            
            {isDownloading ? (
              <Tippy content = {tippymsg} placement="bottom" theme="custom">
              <span>Downloading...({downloadProgress}%)</span>
              </Tippy>
                 ) : (
                buttonText
              )}
    </button>
    </Tippy>
    </>
  );
  
}

export default DownloadButton;

//BAckup code
// import React, { useState, useEffect } from "react";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";

// function DownloadButton({ isCheckAll, ImgUri, downloadImages, wo, isCheck, totalCount }) {
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [currentDownloadList, setCurrentDownloadList] = useState([]);
//   const [downloadCount, setDownloadCount] = useState(0);

//   useEffect(() => {
//     let updatedDownloadList = downloadImages;
//     setCurrentDownloadList(updatedDownloadList);
//   }, [isCheckAll, ImgUri, downloadImages]);

//   const handleDownload = async () => {
//     setIsDownloading(true);
//     setDownloadCount(0);
//     const filenames = new Set();
//     if (currentDownloadList.length <= 10) {
//       for (const item of currentDownloadList) {
//         const [fileurl, downloadFileName, downloadFileNameExtension] = item.split("$");
//         try {
//           const response = await fetch(fileurl);
//           const blob = await response.blob();
//           const a = document.createElement("a");
//           const url = URL.createObjectURL(blob);
//           a.href = url;
//           a.download = `${downloadFileName}.${downloadFileNameExtension}`;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//           URL.revokeObjectURL(url);
//           setDownloadCount((prevCount) => prevCount + 1);
//         } catch (error) {
//           console.error("Error downloading file:", error);
//         }
//       }
//     } else {
//       let zip = new JSZip();
//       let chunkSizeMB = 1024;
//       let currentChunkSize = 0;
//       let chunkIndex = 0;
//       for (let index = 0; index < currentDownloadList.length; index++) {
//         const [fileurl, downloadFileName, downloadFileNameExtension] = currentDownloadList[index].split("$");
//         let uniqueName = downloadFileName;
//         let counter = 1;
//         while (filenames.has(uniqueName + '.' + downloadFileNameExtension)) {
//           uniqueName = `${downloadFileName}(${counter})`;
//           counter++;
//         }
//         filenames.add(uniqueName + '.' + downloadFileNameExtension);
//         try {
//           const response = await fetch(fileurl);
//           const blob = await response.blob();
//           const reader = response.body.getReader();
//           const stream = new ReadableStream({
//             start(controller) {
//               function push() {
//                 reader.read().then(({ done, value }) => {
//                   if (done) {
//                     controller.close();
//                     return;
//                   }
//                   controller.enqueue(value);
//                   push();
//                 });
//               }
//               push();
//             }
//           });

//           const fileSizeMB = blob.size / (1024 * 1024);

//           if (currentChunkSize + fileSizeMB > chunkSizeMB) {
//             const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 6 }, streamFiles: true });
//             saveAs(content, `WorkOrder_${wo}_part${chunkIndex}.zip`);
//             chunkIndex++;
//             currentChunkSize = 0;
//             zip = new JSZip();
//            }
//           zip.file(`${uniqueName}.${downloadFileNameExtension}`, stream);
//           // zip.file(`${uniqueName}.${downloadFileNameExtension}`, blob);
//           currentChunkSize += fileSizeMB;
//           setDownloadCount(index + 1);
//           if (index === currentDownloadList.length - 1) {
//             const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 6 }, streamFiles: true });
//             saveAs(content, `WorkOrder_${wo}_part${chunkIndex}.zip`);
//           }
//         } catch (error) {
//           console.error("Error downloading file:", error);
//         }
//       }
//     }
//     setIsDownloading(false);
//   };

//   const buttonText = currentDownloadList.length > 10 ? "Download Zip" : "Download";

//   return (
//       <button
//         className={`btn-download ${isDownloading ? "is-downloading" : ""}`}
//         onClick={handleDownload}
//         disabled={isDownloading || !currentDownloadList.length || isCheck === 0}
//       >
//         {isDownloading ? `Downloading...(${Math.round(downloadCount/totalCount*100)}%)` : buttonText}
//       </button>
//   );
// }

// export default DownloadButton;
