import React from 'react';
import * as XLSX from 'xlsx';

function DownloadExcelButton({ currentItemsUsers }) {
    function convertJSONToExcel(currentItemsUsers) {
        const headers = [
            'File No.', 'WO No.', 'WOLI No.', 'SA No', 'Captured Date', 'Longitude', 'Latitude', 'Field User Name', 'File Description', 'Filename', 'FileExtension'
        ];

        const dataArray = currentItemsUsers[0]?.data || [];
        const sheetData = [headers]; // Initialize with headers

        dataArray.forEach(userSet => {
            const userArray = userSet.data || [];

            userArray.forEach(user => {
                const { FileNo, workOrderNumber, workOrderLineItemNumber, serviceAppointmentNumber, dateOnImage, longitude, latitude, owner, fileDescription, fileName, imageFileExtension } = user;
                const row = [FileNo, workOrderNumber, workOrderLineItemNumber, serviceAppointmentNumber, dateOnImage, longitude, latitude, owner, fileDescription, fileName, imageFileExtension];
                sheetData.push(row);
            });
        });

        const ws = XLSX.utils.aoa_to_sheet(sheetData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Metadata');

        // Generate a blob from the workbook
        // const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Convert the buffer to a blob
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


        return blob;
    }

    function downloadExcel() {
        const excelBlob = convertJSONToExcel(currentItemsUsers);
        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Metadata.xlsx';
        link.click();
    }

    return (
        <button className={'btn-download'} onClick={downloadExcel}>Download Excel</button>
    );
}

export default DownloadExcelButton;

// ############################################## DOWNLOAD in CSV File #################################################

// one Bug - csv format is removing preceding Zeros (0) 

// import React from 'react';

// function DownloadExcelButton({currentItemsUsers}) {

//     function convertJSONToCSV(currentItemsUsers) {
//         const headers = [
//             'File No.','WO No.','WOLI No.','SA No','Captured Date','Longitude','Latitude','Field User Name','File Description  ','Filename','FileExtension'
//         ];
//         console.log(currentItemsUsers);
//         const dataArray = currentItemsUsers[0]?.data || [];
//         const allCsvRows = [];
    
//         dataArray.forEach(userSet => {
//             const userArray = userSet.data || []; // Access the inner data array
    
//             userArray.forEach(user => {
//                 const { FileNo,workOrderNumber,workOrderLineItemNumber,serviceAppointmentNumber,dateOnImage,longitude,latitude,owner,fileDescription ,fileName,imageFileExtension } = user;                
//                 const csvRow = [FileNo,workOrderNumber,workOrderLineItemNumber,serviceAppointmentNumber,dateOnImage,longitude,latitude,owner,fileDescription ,fileName,imageFileExtension].join(',');
//                 allCsvRows.push(csvRow);
//             });
//         });
    
//         const csvContent = [headers.join(','), ...allCsvRows].join('\n');
//         return csvContent;
//     }
      
//     function downloadCSV() {
//     const csvContent = convertJSONToCSV(currentItemsUsers);
//     const blob = new Blob([csvContent], { type: 'text/csv' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = 'Metadata.xlsx';
//     link.click();
//   }
//   return (
//     <button className={'btn-download'} onClick={downloadCSV}>Download CSV</button>
//   );
// }

// export default DownloadCSVButton;

