import React, { useState, useEffect, useRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import fetchGenericSASToken from "./GetGenericSasToken";
import DownloadButton from "./DownloadButton";
import DownloadExcelButton from "./DownloadExcelButton";
import DownloadVideo from "./DownloadVideo";
import onClickDownloadVideo from "./onClickDownloadVideo";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './TooltipStyles.css';
import GenericDownloadButton from "./GenericDownloadButton";

var history = window.history;
let params = new URLSearchParams(window.location.search);

let workid = params.get("WorkOrderID");
let woli = params.get("WorkOrderLineItemID");
let sa = params.get("ServiceAppointmnetID");
let workNo = params.get("WorkOrderNo");
let woliNo = params.get("WorkOrderLineItemNo");
let saNo = params.get("ServiceAppointmnetNo");
let saCaseNumber = params.get("SalesforceCaseNumber");
let searchMetaDataKey = params.get("SearchMetaDataKey");
let searchMetaDataValue = params.get("SearchMetaDataValue");

let saOrderNumber = params.get("SalesforceOrderNumber");
let saAccount = params.get("SalesforceAccount");
let saCart = params.get("SalesforceCart");

let siteId = params.get("SiteId");
let siteName = params.get("SiteName");
let patchId = params.get("PatchId");
let patchName = params.get("PatchName");
let recordType = params.get("RecordType");
let updateFlag = params.get("updateFlag");

let ptwId = params.get("PTWID");
let ptwNumber = params.get("PTWNumber");

let accessToken = params.get("AccessToken");

let fullUrlWithToken = window.location.href;

//DEV SAS TOKEN
//let genericStorageSasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-12T17:48:20Z&st=2024-02-12T09:48:20Z&spr=https&sig=9Kpc7RsFJ5Jjo77SQLgC1eAKeWg3fvNIGXQi1e%2FOvfk%3D";
//let mediastoreSasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-12T17:46:49Z&st=2024-02-12T09:46:49Z&spr=https&sig=kvWwsFwrA4wVCYuExOFXHb8Oo%2F9uwEUMmALjsoTe5Dk%3D";
//PPN SAS TOKEN
//let genericStorageSasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-01T14:14:53Z&st=2024-03-01T06:14:53Z&spr=https&sig=vhCvRxWakLVWPd7BOp6EW4S1a0WmBIMTfrSKkKeM7OI%3D";
//let mediastoreSasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-01T14:15:36Z&st=2024-03-01T06:15:36Z&spr=https&sig=UPGZc0S8YqWliYunha5asCKgdGYxAC5JmyAaWX5MoIc%3D";
//TRN SAS TOKEN
//let genericStorageSasToken = "?sv=2022-11-02&ss=bfqt&srt=so&sp=rwdlacupiytfx&se=2024-08-01T17:13:13Z&st=2024-03-01T09:13:13Z&spr=https&sig=rlwIg3d0kHxqB0ckHN0%2F99tYpcwEtgMfDWsXEBjmZIg%3D";
//let mediastoreSasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-08-01T17:13:44Z&st=2024-03-01T09:13:44Z&spr=https&sig=X2sKXdB3H9Fi76AGuSw68MOzwvfj98KyT9f6ch8T8X4%3D";

if (history.replaceState) history.replaceState({}, "", "/");
if (
  saCaseNumber != null ||
  ptwNumber != null ||
  saOrderNumber != null ||
  searchMetaDataKey != null ||
  searchMetaDataValue != null ||
  saAccount != null ||
  saCart != null
) {
  document.oncontextmenu = function (e) {
    if (e.button == 2) {
      e.preventDefault();
      return false;
    }
  };
  document.onkeydown = (e) => {
    if (e.ctrlKey && e.keyCode == "C".charCodeAt(0)) {
      return false;
    }
    if (e.shiftKey && e.keyCode == 121) {
      return false;
    }
    if (e.keyCode == 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
      return false;
    }
  };
}

const Gallery = () => {
  const [users, setUsers] = useState([]);
  const [wo, setWo] = useState([]);
  const [unavailable, setUnavailable] = useState(false);
  const [serviceAppointment, setServiceAppointment] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [photoIndex, setPhotoIndex] = useState();
  const [blobUrls, setBlobUrls] = useState([]);
  const [downloadImages, setDownloadImages] = useState([]);
  const [checkedState, setCheckedState] = useState(false);
  const [downloaded, setIsDownloaded] = useState(false);
  const [dateTime, setDateTime] = useState([]);
  const [ocrData, setOcrData] = useState([]);
  const [lat, setLat] = useState([]);
  const [long, setLong] = useState([]);
  const [capturedDate, setCapturedDate] = useState([]);
  const [dbInsertedDateTime, setDbInsertedDateTime] = useState([]);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [uploadedBy, setUploadedBy] = useState([]);
  const [organization, setOrganization] = useState([]);
  ///////
  const [fileDesc, setFiledesc] = useState([]);
  ///////
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [scase, setScase] = useState([]);
  const [genUsers, setgenUsers] = useState([]);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [showOnWebChecked, setShowOnWebChecked] = useState({});
  const [newlyUncheckedIds, setNewlyUncheckedIds] = useState([]);
  const [newlyCheckedIds, setNewlyCheckedIds] = useState([]);

  const [usersReinstatement, setUsersReinstatement] = useState([]);
  const [expansionState, setExpansionState] = useState({});

  const [genericStorageSasToken, setgenericStorageSasToken] = useState("");

  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(1); 
  const [downloadProgress, setDownloadProgress] = useState({});

  // Calculate the indexes of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // For Generic Attachment
  // const currentItemsGenUsers = genUsers.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCaptionToggle = () => {
    setIsCaptionVisible(!isCaptionVisible);
  };

  // Option 1: Using JSX for Button Definition
  const customButtons = [
    <button
      key="captionToggle"
      className="toggleImageCaptionButton"
      onClick={handleCaptionToggle}
    >
      {isCaptionVisible ? "Hide metadata" : "Show metadata"}
    </button>,
  ];

  let idArray = [];
  let defaulCheckedImages = [];
  let arrayWoli = [];

  const workdIds = wo.map((woId) => {
    return woId.workOrderNumber;
  });

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  //#region Loader
  const loaderOverlay = document.createElement("div");
  loaderOverlay.id = "loader-overlay";
  const loader = document.createElement("div");
  loader.id = "loader";
  document.body.appendChild(loaderOverlay);
  document.body.appendChild(loader);
  const loaderdiv = document.getElementById("loader");
  const loaderOverlaydiv = document.getElementById("loader-overlay");
  //#endregion

  let searchApiUrl = process.env.REACT_APP_FUNCTION_APP_SEARCH_API_URL;
  let searchDataApiUrl = process.env.REACT_APP_FUNCTION_APP_SEARCH_DATA_API_URL;
  let showOnWebApiUrl = process.env.REACT_APP_FUNCTION_APP_SHOWONWEB_API_URL;

  //#region API Env Urls
  //DEV Search URL
  //let searchApiUrl = `https://fa-euw-dev-mediastoresearch.azurewebsites.net/api/`;
  //let searchApiUrl = `https://fa-euw-dev-mediastoresearch-pagination.azurewebsites.net/api/`;
  //let showOnWebApiUrl = `https://fa-euw-dev-mediastoreshowonweb.azurewebsites.net/api/`;

  //PPN Search URL
  //let searchApiUrl = `https://fa-euw-ppn-mediastoresearch.azurewebsites.net/api/`;
  //let showOnWebApiUrl = `https://fa-euw-ppn-mediastoreshowonweb.azurewebsites.net/api/`;

  //TRN Search URL
  //let searchApiUrl = `https://fa-euw-trn-mediastoresearch.azurewebsites.net/api/`;
  //let showOnWebApiUrl = `https://fa-euw-trn-mediastoreshowonweb.azurewebsites.net/api/`;

  //Prod Search URL primary
  //let searchApiUrl = `https://fauewprodmediastore.azurewebsites.net/api/`;
  //let showOnWebApiUrl = ``https://fauewprodmediastore.azurewebsites.net/api/`;

  //Prod Search URL secondary
  //let searchApiUrl = `https://fauenprodmediastore.azurewebsites.net/api/`;
  //let showOnWebApiUrl = `https://fauenprodmediastore.azurewebsites.net/api/`;
  //#endregion

  const getSelectedUsers = async () => {
    // let searchUrl = searchApiUrl.concat(`TestSearchAPI?isGroupByRequired=false&`);
    let searchUrl;
    if ((searchMetaDataKey && searchMetaDataValue) != null) {
      searchUrl = searchDataApiUrl.concat(`Search?`);
    } else {
      searchUrl = searchApiUrl.concat(`Search?`);
    }
    if (workid != null)
      searchUrl = searchUrl.concat(`WorkOrderID=${workid}` + `&`);
    if (workNo != null)
      searchUrl = searchUrl.concat(`WorkOrderNo=${workNo}` + `&`);
    if (woli != null)
      searchUrl = searchUrl.concat(`WorkOrderLineItemID=${woli}` + `&`);
    if (woliNo != null)
      searchUrl = searchUrl.concat(`WorkOrderLineItemNo=${woliNo}` + `&`);
    if (sa != null)
      searchUrl = searchUrl.concat(`ServiceAppointmnetID=${sa}` + `&`);
    if (saNo != null)
      searchUrl = searchUrl.concat(`ServiceAppointmnetNo=${saNo}` + `&`);
    if (saCaseNumber != null)
      searchUrl = searchUrl.concat(
        `SalesforceCaseNumber=${saCaseNumber}` + `&`
      );
    //SQS
    if (searchMetaDataKey != null)
      searchUrl = searchUrl.concat(
        `SearchMetaDataKey=${searchMetaDataKey}` + `&`
      );
    if (searchMetaDataValue != null)
      searchUrl = searchUrl.concat(
        `SearchMetaDataValue=${searchMetaDataValue}` + `&`
      );
    //PS
    if (saOrderNumber != null)
      searchUrl = searchUrl.concat(
        `SalesforceOrderNumber=${saOrderNumber}` + `&`
      );
    if (saAccount != null)
      searchUrl = searchUrl.concat(`SalesforceAccount=${saAccount}` + `&`);
    if (saCart != null)
      searchUrl = searchUrl.concat(`SalesforceCart=${saCart}` + `&`);
    //Reinstatement
    if (siteId != null) searchUrl = searchUrl.concat(`SiteId=${siteId}` + `&`);
    if (siteName != null)
      searchUrl = searchUrl.concat(`SiteName=${siteName}` + `&`);
    if (patchId != null)
      searchUrl = searchUrl.concat(`PatchId=${patchId}` + `&`);
    if (patchName != null)
      searchUrl = searchUrl.concat(`PatchName=${patchName}` + `&`);
    //PTW
    if (ptwId != null) searchUrl = searchUrl.concat(`PTWID=${ptwId}` + `&`);
    if (ptwNumber != null)
      searchUrl = searchUrl.concat(`PTWNumber=${ptwNumber}` + `&`);

    // Remove the trailing '&'
    searchUrl = searchUrl.substring(0, searchUrl.length - 1);
    console.log("Search api url with parameters :- " + searchUrl);
    loaderdiv.style.visibility = "visible";
    loaderOverlaydiv.style.display = "flex";
    const response = await fetch(searchUrl, {
      method: "get",
      headers: {
        Authorization: "Bearer" + " " + accessToken,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      loaderdiv.style.visibility = "hidden";
      loaderOverlaydiv.style.display = "none";
      var Users = await response.json();
      const allIds = Users.map((user) => user.id);
      allIds.forEach((id) => {
        idArray.push(id);
      });
      const checkedState = {};
      Users.forEach((user) => {
        checkedState[user.id] = user.showOnWeb;
      });
      defaulCheckedImages = Users.reduce((acc, user) => {
        if (user.showOnWeb === "true") {
          acc.push(user.id);
        }
        return acc;
      }, []);
      setShowOnWebChecked(checkedState);

      function groupBy(arr, prop) {
        return Object.values(
          arr.reduce((aggregate, item) => {
            const val = item[prop];
            if (!aggregate[val]) {
              aggregate[val] = {
                [prop]: val,
                data: [],
              };
            }
            aggregate[val].data.push(item);
            return aggregate;
          }, {})
        );
      }

      // const groupBy = (data) => {
      //   const groupedData = {};

      //   data.forEach((item) => {
      //     const { siteName, patchName, workOrderLineItemWorkType, workOrderLineItemNumber, serviceAppointmentNumber } = item;

      //     if (recordType === "Site" || recordType === "Patch") {
      //       if (!groupedData[siteName]) {
      //         groupedData[siteName] = {
      //           patches: {},
      //         };
      //       }
      //       if (!groupedData[siteName].patches[patchName]) {
      //         groupedData[siteName].patches[patchName] = [];
      //       }
      //       groupedData[siteName].patches[patchName].push(item);
      //     }else if(recordType === "Reinstatement_Site_WOLI"){

      //     }else{

      //     }

      //     if (!siteName && !patchName) {
      //       let key = ``;
      //       if(!workOrderLineItemWorkType){
      //         key = `NA@@${workOrderLineItemNumber}@@${serviceAppointmentNumber}`;
      //       }else{
      //         key = `${workOrderLineItemWorkType}@@${workOrderLineItemNumber}@@${serviceAppointmentNumber}`;
      //       }
      //       if (!groupedData[key]) {
      //         groupedData[key] = [];
      //       }

      //       if(!groupedData[workOrderLineItemWorkType]){

      //       }

      //       groupedData[key].push(item);
      //     } else {
      //       // Handle the first two scenarios by grouping by SiteName and PatchName
      //       if (!groupedData[siteName]) {
      //         groupedData[siteName] = {
      //           patches: {},
      //         };
      //       }

      //       if (!groupedData[siteName].patches[patchName]) {
      //         groupedData[siteName].patches[patchName] = [];
      //       }

      //       groupedData[siteName].patches[patchName].push(item);
      //     }
      //   });

      //   return groupedData;
      // };
      const groupByReinstatement = (data) => {
        const groupedData = {};

        data.forEach((item) => {
          const {
            siteName,
            patchName,
            workOrderLineItemWorkType,
            workOrderLineItemNumber,
            serviceAppointmentNumber,
          } = item;

          if (recordType === "Site" || recordType === "Patch") {
            // Group by siteName
            if (!groupedData[siteName]) {
              groupedData[siteName] = {};
            }

            // Group by patchName inside siteName
            if (!groupedData[siteName][patchName]) {
              groupedData[siteName][patchName] = {};
            }

            // Group by workOrderLineItemWorkType inside patchName
            if (!groupedData[siteName][patchName][workOrderLineItemWorkType]) {
              groupedData[siteName][patchName][workOrderLineItemWorkType] = {};
            }

            // Group by workOrderLineItemNumber inside workOrderLineItemWorkType
            if (
              !groupedData[siteName][patchName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ]
            ) {
              groupedData[siteName][patchName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ] = {};
            }

            // Group by serviceAppointmentNumber inside workOrderLineItemNumber
            if (
              !groupedData[siteName][patchName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ][serviceAppointmentNumber]
            ) {
              groupedData[siteName][patchName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ][serviceAppointmentNumber] = [];
            }

            // Push the item
            groupedData[siteName][patchName][workOrderLineItemWorkType][
              workOrderLineItemNumber
            ][serviceAppointmentNumber].push(item);
          } else if (recordType === "Reinstatement_Site_WOLI") {
            // Group by siteName
            if (!groupedData[siteName]) {
              groupedData[siteName] = {};
            }

            // Group by workOrderLineItemWorkType inside siteName
            if (!groupedData[siteName][workOrderLineItemWorkType]) {
              groupedData[siteName][workOrderLineItemWorkType] = {};
            }

            // Group by workOrderLineItemNumber inside workOrderLineItemWorkType
            if (
              !groupedData[siteName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ]
            ) {
              groupedData[siteName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ] = {};
            }

            // Group by serviceAppointmentNumber inside workOrderLineItemNumber
            if (
              !groupedData[siteName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ][serviceAppointmentNumber]
            ) {
              groupedData[siteName][workOrderLineItemWorkType][
                workOrderLineItemNumber
              ][serviceAppointmentNumber] = [];
            }

            // Push the item
            groupedData[siteName][workOrderLineItemWorkType][
              workOrderLineItemNumber
            ][serviceAppointmentNumber].push(item);
          } else {
            // Group by workOrderLineItemNumber
            if (!groupedData[workOrderLineItemNumber]) {
              groupedData[workOrderLineItemNumber] = {};
            }

            // Group by serviceAppointmentNumber inside workOrderLineItemNumber
            if (
              !groupedData[workOrderLineItemNumber][serviceAppointmentNumber]
            ) {
              groupedData[workOrderLineItemNumber][serviceAppointmentNumber] =
                [];
            }

            // Push the item
            groupedData[workOrderLineItemNumber][serviceAppointmentNumber].push(
              item
            );
          }
        });

        return groupedData;
      };

      if (
        saCaseNumber != null ||
        ptwId != null ||
        saOrderNumber != null ||
        searchMetaDataKey != null ||
        searchMetaDataValue != null ||
        saAccount != null ||
        saCart != null
      ) {
        if (Users.length === 0) {
          setUnavailable(true);
        } else {
          let genericStorageSasToken = await fetchGenericSASToken(accessToken);
          setgenericStorageSasToken(genericStorageSasToken);
          setgenUsers(Users);
          var SC = Users;
          setScase(SC);
        }
      } else {
        if (Users.length === 0) {
          setUnavailable(true);
        } else {
          if (
            recordType === "Site" ||
            recordType === "Patch" ||
            recordType === "Reinstatement_Site_WOLI"
          ) {
            const groupedReinstatement = groupByReinstatement(Users);
            // console.log("groupedReinstatement--",groupedReinstatement);
            setUsersReinstatement(groupedReinstatement);
          } else {
            const grouped = groupBy(Users, "workOrderLineItemNumber").map(
              (item) => ({
                ...item,
                data: groupBy(item.data, "serviceAppointmentNumber"),
              })
            );
            // console.log("grouped",grouped);
            setUsers(grouped);
          }
          var WO = Users;
          setWo(WO);
        }
      }
    } else if (response.status === 400) {
      loaderdiv.style.visibility = "hidden";
      loaderOverlaydiv.style.display = "none";
      setUnavailable(true);
    } else if (response.status === 401) {
      loaderdiv.style.visibility = "hidden";
      loaderOverlaydiv.style.display = "none";
      setErrorMessage(
        "You currently do not have access to this page.\n Please try again."
      );
    }
  };

  const handleAdd = (image) => {
    const selectedImages = [...downloadImages];
    selectedImages.push(image);
    setDownloadImages(selectedImages);
  };

  const handleRemove = (image) => {
    const selectedImages = downloadImages.filter((img) => img !== image);
    setDownloadImages(selectedImages);
  };
  /*ImgUri of total
   const ImgUri = wo.map((user) => {
     // return user.finalImageURL;
     return (
       user.finalImageURL + "$" + user.fileName + "$" + user.imageFileExtension
     );
   });
  */

  // Release 4.0 starts
  const genImgUri = scase.map((user) => {
    return user.fileFinalURL + "$" + user.fileName + "$" + user.fileExtension;
  });
  // Release 4.0 ends
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  /*Select total
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(wo.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  }; */
  //Select single page only
  // const handleSelectAll = () => {
  //   setIsCheckAll((prevIsCheckAll) => {
  //     if (prevIsCheckAll) {
  //       setIsCheck([]);
  //       setDownloadImages([]);
  //     } else {
  //       setIsCheck(arrayWoli.map((li) => li.id));
  //     }
  //     return !prevIsCheckAll;
  //   });
  // };

  const handleSelectAll = () => {
    setIsCheckAll((prevIsCheckAll) => {
      const newIsCheckAll = !prevIsCheckAll;

      if (newIsCheckAll) {
        setIsCheck(arrayWoli.map((li) => li.id));
        setDownloadImages(ImgUri);
      } else {
        setIsCheck([]);
        setDownloadImages([]);
      }
      return newIsCheckAll;
    });
  };
  // const genHandleSelectAll = () => {
  //   setIsCheckAll((prevIsCheckAll) => {
  //     const newIsCheckAll = !prevIsCheckAll;
  
  //     if (newIsCheckAll) {
  //       setIsCheck(scase.map((li) => li.id));
  //       setDownloadImages(genImgUri);
  //     } else {
  //       setIsCheck([]);
  //       setDownloadImages([]);
  //     }
  //     return newIsCheckAll;
  //   });
  // }; 

  const genHandleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(scase.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleShowOnWebClick = async () => {
    const checkedCheckboxes = document.querySelectorAll(
      'input[type="checkbox"][name^="selectShowOnWebName"]:checked'
    );
    const checkedIds = [];
    const nameOfFilesToShowOnWebsite = [];
    for (let i = 0; i < checkedCheckboxes.length; i++) {
      const checkbox = checkedCheckboxes[i];
      if (checkbox.checked) {
        checkedIds.push(checkbox.id);
        nameOfFilesToShowOnWebsite.push(checkbox.dataset.fileName);
      }
    }
    if (checkedCheckboxes.length > 3) {
      createDialogBox(
        "Warning",
        "Only 3 images are eligible to show on webiste. Please select less than or equal to 3 images",
        [],
        closeDialogBox
      );
    } else if (checkedCheckboxes.length > 0 && checkedCheckboxes.length <= 3) {
      let searchShowOnWebUrl = showOnWebApiUrl.concat(`SearchFlagHttpTrigger?`);
      if (workNo != null) {
        searchShowOnWebUrl = searchShowOnWebUrl.concat(
          `WorkOrderNo=${workNo}` + `&`
        );
      }
      searchShowOnWebUrl = searchShowOnWebUrl.concat(`ShowOnWeb=true`);
      //searchShowOnWebUrl = searchShowOnWebUrl.substring(0,searchShowOnWebUrl.length-1);
      console.log(searchShowOnWebUrl);
      loaderdiv.style.visibility = "visible";
      loaderOverlaydiv.style.display = "flex";
      await fetch(searchShowOnWebUrl, {
        method: "get",
        headers: {
          Authorization: "Bearer" + " " + accessToken,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(async (data) => {
              const idsWithFlagTrue = data.map((record) => record.id);
              const filterData = data.filter(
                (record) => record.showOnWeb === "true"
              );
              const commonValues = idsWithFlagTrue.filter((val) =>
                checkedIds.includes(val)
              );
              const totalCombinedArrayLength =
                checkedIds.length +
                idsWithFlagTrue.length -
                commonValues.length;
              if (
                totalCombinedArrayLength > 3 &&
                !idsWithFlagTrue.every((id) => checkedIds.includes(id))
              ) {
                loaderdiv.style.visibility = "hidden";
                loaderOverlaydiv.style.display = "none";
                const dialogOverlay = document.createElement("div");
                dialogOverlay.classList.add("dialog-overlay");
                const dialogBox = document.createElement("div");
                dialogBox.id = "dialog-box";
                const dialogBoxTitle = document.createElement("div");
                dialogBoxTitle.id = "dialog-box-title";
                dialogBoxTitle.innerHTML = "Information";
                const dialogBoxMessage = document.createElement("div");
                dialogBoxMessage.id = "dialog-box-message";
                dialogBoxMessage.innerHTML = `Below image(s) are already marked to shown on website. Do you want to override them?`;
                const orderListFilename = document.createElement("ol");
                orderListFilename.style.padding = "0 0 0 20px";
                orderListFilename.style.overflow = "auto";
                filterData.forEach((record) => {
                  const listItem = document.createElement("li");
                  listItem.textContent =
                    record.fileName + " in " + record.serviceAppointmentNumber;
                  orderListFilename.appendChild(listItem);
                });
                dialogBoxMessage.appendChild(orderListFilename);
                const yesButton = document.createElement("button");
                yesButton.id = "dialog-box-yes-button";
                yesButton.innerText = "Yes";
                yesButton.addEventListener("click", async () => {
                  closeDialogBox();
                  await updateShowOnWebFlag(checkedIds, newlyUncheckedIds);
                });
                const noButton = document.createElement("button");
                noButton.id = "dialog-box-no-button";
                noButton.innerText = "No";
                noButton.addEventListener("click", closeDialogBox);
                dialogBox.appendChild(dialogBoxTitle);
                dialogBox.appendChild(dialogBoxMessage);
                dialogBox.appendChild(yesButton);
                dialogBox.appendChild(noButton);
                document.body.appendChild(dialogOverlay);
                document.body.appendChild(dialogBox);
              } else {
                loaderdiv.style.visibility = "hidden";
                loaderOverlaydiv.style.display = "none";
                const dialogOverlay = document.createElement("div");
                dialogOverlay.classList.add("dialog-overlay");
                const dialogBox = document.createElement("div");
                dialogBox.id = "dialog-box";
                const dialogBoxTitle = document.createElement("div");
                dialogBoxTitle.id = "dialog-box-title";
                dialogBoxTitle.innerHTML = "Information";
                const dialogBoxMessage = document.createElement("div");
                dialogBoxMessage.id = "dialog-box-message";
                dialogBoxMessage.innerHTML = `Below image(s) will be shown on website. Do you want to proceed ?`;
                const orderListFilename = document.createElement("ol");
                orderListFilename.style.padding = "0 0 0 20px";
                orderListFilename.style.overflow = "auto";
                nameOfFilesToShowOnWebsite.forEach((record) => {
                  const listItem = document.createElement("li");
                  listItem.textContent = record;
                  orderListFilename.appendChild(listItem);
                });
                dialogBoxMessage.appendChild(orderListFilename);
                const yesButton = document.createElement("button");
                yesButton.id = "dialog-box-yes-button";
                yesButton.innerText = "Yes";
                yesButton.addEventListener("click", async () => {
                  closeDialogBox();
                  await updateShowOnWebFlag(checkedIds, newlyUncheckedIds);
                });
                const noButton = document.createElement("button");
                noButton.id = "dialog-box-no-button";
                noButton.innerText = "No";
                noButton.addEventListener("click", closeDialogBox);
                dialogBox.appendChild(dialogBoxTitle);
                dialogBox.appendChild(dialogBoxMessage);
                dialogBox.appendChild(yesButton);
                dialogBox.appendChild(noButton);
                document.body.appendChild(dialogOverlay);
                document.body.appendChild(dialogBox);
              }
            });
          } else if (response.status === 401) {
            loaderdiv.style.visibility = "hidden";
            loaderOverlaydiv.style.display = "none";
            let errMsg = `Token expired`;
            createDialogBox("Error", errMsg, [], closeDialogBox);
          } else {
            response.json().then((data) => {
              let errMsg = `Error: ${data.message}`;
              createDialogBox("Error", errMsg, [], closeDialogBox);
            });
          }
        })
        .catch((error) => {
          loaderdiv.style.visibility = "hidden";
          loaderOverlaydiv.style.display = "none";
          console.error("Error fetching images:", error);
          createDialogBox("Error", "Error : " + error, [], closeDialogBox);
        });
    } else {
      createDialogBox(
        "Warning",
        "No image selected to show on website",
        [],
        closeDialogBox
      );
    }
  };

  const handleShowOnWebForSFCaseNumberClick = async () => {
    const checkedCheckboxes = document.querySelectorAll(
      'input[type="checkbox"][name^="selectShowOnWebName"]:checked'
    );
    const checkedIds = [];
    const nameOfFilesToShowOnWebsite = [];
    for (let i = 0; i < checkedCheckboxes.length; i++) {
      const checkbox = checkedCheckboxes[i];
      if (checkbox.checked) {
        checkedIds.push(checkbox.id);
        nameOfFilesToShowOnWebsite.push(checkbox.dataset.fileName);
      }
    }
    if (checkedCheckboxes.length > 3) {
      createDialogBox(
        "Warning",
        "Only 3 images are eligible to show on webiste. Please select less than or equal to 3 images",
        [],
        closeDialogBox
      );
    } else if (checkedCheckboxes.length > 0 && checkedCheckboxes.length <= 3) {
      let searchShowOnWebUrl = showOnWebApiUrl.concat(`SearchFlagHttpTrigger?`);
      if (saCaseNumber != null) {
        searchShowOnWebUrl = searchShowOnWebUrl.concat(
          `SalesforceCaseNumber=${saCaseNumber}` + `&`
        );
      }
      searchShowOnWebUrl = searchShowOnWebUrl.concat(`ShowOnWeb=true`);
      //searchShowOnWebUrl = searchShowOnWebUrl.substring(0,searchShowOnWebUrl.length-1);
      console.log(searchShowOnWebUrl);
      loaderdiv.style.visibility = "visible";
      loaderOverlaydiv.style.display = "flex";
      await fetch(searchShowOnWebUrl, {
        method: "get",
        headers: {
          Authorization: "Bearer" + " " + accessToken,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(async (data) => {
              // const idsWithFlagTrue = data.map(record => record.id);
              // const filterData = data.filter(record => record.showOnWeb === "true");
              // const commonValues = idsWithFlagTrue.filter(val => checkedIds.includes(val));
              // const totalCombinedArrayLength = checkedIds.length + idsWithFlagTrue.length - commonValues.length;
              // if(totalCombinedArrayLength > 3){
              //   loaderdiv.style.visibility = 'hidden';
              //   loaderOverlaydiv.style.display = 'none';
              //   const dialogOverlay = document.createElement('div');
              //   dialogOverlay.classList.add('dialog-overlay');
              //   const dialogBox = document.createElement('div');
              //   dialogBox.id = 'dialog-box';
              //   const dialogBoxTitle = document.createElement('div');
              //   dialogBoxTitle.id = 'dialog-box-title';
              //   dialogBoxTitle.innerHTML = 'Information';
              //   const dialogBoxMessage = document.createElement('div');
              //   dialogBoxMessage.id = 'dialog-box-message';
              //   dialogBoxMessage.innerHTML = `Below image(s) are already marked to shown on website. Do you want to override them?`;
              //   const orderListFilename = document.createElement('ol');
              //   orderListFilename.style.padding = '0 0 0 20px';
              //   orderListFilename.style.overflow = 'auto';
              //   filterData.forEach(record => {
              //     const listItem = document.createElement('li');
              //     listItem.textContent = record.fileName;
              //     orderListFilename.appendChild(listItem);
              //   });
              //   dialogBoxMessage.appendChild(orderListFilename);
              //   const yesButton = document.createElement('button');
              //   yesButton.id = 'dialog-box-yes-button';
              //   yesButton.innerText = 'Yes';
              //   yesButton.addEventListener('click', async () => {
              //     closeDialogBox();
              //     await updateShowOnWebFlag(checkedIds,newlyUncheckedIds);
              //   });
              //   const noButton = document.createElement('button');
              //   noButton.id = 'dialog-box-no-button';
              //   noButton.innerText = 'No';
              //   noButton.addEventListener('click', closeDialogBox);
              //   dialogBox.appendChild(dialogBoxTitle);
              //   dialogBox.appendChild(dialogBoxMessage);
              //   dialogBox.appendChild(yesButton);
              //   dialogBox.appendChild(noButton);
              //   document.body.appendChild(dialogOverlay);
              //   document.body.appendChild(dialogBox);
              // }else{
              //   loaderdiv.style.visibility = 'hidden';
              //   loaderOverlaydiv.style.display = 'none';
              //   const dialogOverlay = document.createElement('div');
              //   dialogOverlay.classList.add('dialog-overlay');
              //   const dialogBox = document.createElement('div');
              //   dialogBox.id = 'dialog-box';
              //   const dialogBoxTitle = document.createElement('div');
              //   dialogBoxTitle.id = 'dialog-box-title';
              //   dialogBoxTitle.innerHTML = 'Information';
              //   const dialogBoxMessage = document.createElement('div');
              //   dialogBoxMessage.id = 'dialog-box-message';
              //   dialogBoxMessage.innerHTML = `Below images will be shown on website. Do you want to proceed ?`;
              //   const orderListFilename = document.createElement('ol');
              //   orderListFilename.style.padding = '0 0 0 20px';
              //   orderListFilename.style.overflow = 'auto';
              //   nameOfFilesToShowOnWebsite.forEach(record => {
              //     const listItem = document.createElement('li');
              //     listItem.textContent = record;
              //     orderListFilename.appendChild(listItem);
              //   });
              //   dialogBoxMessage.appendChild(orderListFilename);
              //   const yesButton = document.createElement('button');
              //   yesButton.id = 'dialog-box-yes-button';
              //   yesButton.innerText = 'Yes';
              //   yesButton.addEventListener('click', async () => {
              //     closeDialogBox();
              //     await updateShowOnWebFlag(checkedIds,newlyUncheckedIds);
              //   });
              //   const noButton = document.createElement('button');
              //   noButton.id = 'dialog-box-no-button';
              //   noButton.innerText = 'No';
              //   noButton.addEventListener('click', closeDialogBox);
              //   dialogBox.appendChild(dialogBoxTitle);
              //   dialogBox.appendChild(dialogBoxMessage);
              //   dialogBox.appendChild(yesButton);
              //   dialogBox.appendChild(noButton);
              //   document.body.appendChild(dialogOverlay);
              //   document.body.appendChild(dialogBox);
              // }
              loaderdiv.style.visibility = "hidden";
              loaderOverlaydiv.style.display = "none";
              const dialogOverlay = document.createElement("div");
              dialogOverlay.classList.add("dialog-overlay");
              const dialogBox = document.createElement("div");
              dialogBox.id = "dialog-box";
              const dialogBoxTitle = document.createElement("div");
              dialogBoxTitle.id = "dialog-box-title";
              dialogBoxTitle.innerHTML = "Information";
              const dialogBoxMessage = document.createElement("div");
              dialogBoxMessage.id = "dialog-box-message";
              dialogBoxMessage.innerHTML = `Below image(s) will be shown on website. Do you want to proceed ?`;
              const orderListFilename = document.createElement("ol");
              orderListFilename.style.padding = "0 0 0 20px";
              orderListFilename.style.overflow = "auto";
              nameOfFilesToShowOnWebsite.forEach((record) => {
                const listItem = document.createElement("li");
                listItem.textContent = record;
                orderListFilename.appendChild(listItem);
              });
              dialogBoxMessage.appendChild(orderListFilename);
              const yesButton = document.createElement("button");
              yesButton.id = "dialog-box-yes-button";
              yesButton.innerText = "Yes";
              yesButton.addEventListener("click", async () => {
                closeDialogBox();
                await updateShowOnWebFlag(checkedIds, newlyUncheckedIds);
              });
              const noButton = document.createElement("button");
              noButton.id = "dialog-box-no-button";
              noButton.innerText = "No";
              noButton.addEventListener("click", closeDialogBox);
              dialogBox.appendChild(dialogBoxTitle);
              dialogBox.appendChild(dialogBoxMessage);
              dialogBox.appendChild(yesButton);
              dialogBox.appendChild(noButton);
              document.body.appendChild(dialogOverlay);
              document.body.appendChild(dialogBox);
            });
          } else if (response.status === 401) {
            loaderdiv.style.visibility = "hidden";
            loaderOverlaydiv.style.display = "none";
            let errMsg = `Token expired`;
            createDialogBox("Error", errMsg, [], closeDialogBox);
          } else {
            response.json().then((data) => {
              let errMsg = `Error: ${data.message}`;
              createDialogBox("Error", errMsg, [], closeDialogBox);
            });
          }
        })
        .catch((error) => {
          loaderdiv.style.visibility = "hidden";
          loaderOverlaydiv.style.display = "none";
          console.error("Error fetching images:", error);
          createDialogBox(
            "Error",
            "Error fetching images: " + error,
            [],
            closeDialogBox
          );
        });
    } else {
      createDialogBox(
        "Warning",
        "No image selected to show on website",
        [],
        closeDialogBox
      );
    }
  };

  function createDialogBox(title, message, elements, onClose) {
    const dialogOverlay = document.createElement("div");
    dialogOverlay.classList.add("dialog-overlay");
    const dialogBox = document.createElement("div");
    dialogBox.id = "dialog-box";
    const dialogBoxTitle = document.createElement("div");
    dialogBoxTitle.id = "dialog-box-title";
    dialogBoxTitle.innerHTML = title;
    const dialogBoxMessage = document.createElement("div");
    dialogBoxMessage.id = "dialog-box-message";
    dialogBoxMessage.innerHTML = message;
    // Add any additional elements to the message
    elements.forEach((element) => {
      const el = document.createElement(element.type);
      el.textContent = element.text;
      if (element.attributes) {
        Object.keys(element.attributes).forEach((attr) => {
          el.setAttribute(attr, element.attributes[attr]);
        });
      }
      if (element.listener) {
        el.addEventListener(element.listener.event, element.listener.callback);
      }
      dialogBoxMessage.appendChild(el);
    });
    const closeButton = document.createElement("button");
    closeButton.id = "dialog-box-close-button";
    closeButton.innerText = "Close";
    closeButton.addEventListener("click", onClose);
    dialogBox.appendChild(dialogBoxTitle);
    dialogBox.appendChild(dialogBoxMessage);
    dialogBox.appendChild(closeButton);
    document.body.appendChild(dialogOverlay);
    document.body.appendChild(dialogBox);
  }

  async function updateShowOnWebFlag(checkedIdsArray, unCheckedIdsArray) {
    let arrayOfCheckedRecords = checkedIdsArray;
    let arrayOfUncheckedRecords = unCheckedIdsArray;
    let updateFlagUrl = showOnWebApiUrl.concat(`UpdateFlagHttpTrigger`);
    loaderdiv.style.visibility = "visible";
    loaderOverlaydiv.style.display = "flex";
    await fetch(updateFlagUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer" + " " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        arrayOfCheckedRecords: arrayOfCheckedRecords,
        arrayOfUncheckedRecords: arrayOfUncheckedRecords,
        workOrderNumber: workNo,
        salesforceCaseNumber: saCaseNumber,
      }),
    })
      .then((response) => {
        loaderdiv.style.visibility = "hidden";
        loaderOverlaydiv.style.display = "none";
        if (response.status === 204) {
          createDialogBox(
            "Success",
            "Updated Successfully",
            [],
            closeAfterUpdatingDialogBox
          );
        } else if (response.status === 401) {
          response.json().then((data) => {
            let errMsg = `Error: ${data.message}`;
            createDialogBox("Error", errMsg, [], closeDialogBox);
          });
        } else {
          response.json().then((data) => {
            let errMsg = `Error: ${data.message}`;
            createDialogBox("Error", errMsg, [], closeDialogBox);
          });
        }
      })
      .catch((error) => {
        loaderdiv.style.visibility = "hidden";
        loaderOverlaydiv.style.display = "none";
        console.error("Error while Updating flag:", error);
        createDialogBox(
          "Error",
          "Error while Updating flag : " + error,
          [],
          closeAfterUpdatingDialogBox
        );
      });
  }

  const closeDialogBox = () => {
    const dialogBox = document.querySelector("#dialog-box");
    const dialogOverlay = document.querySelector(".dialog-overlay");
    dialogBox.parentNode.removeChild(dialogBox);
    dialogOverlay.parentNode.removeChild(dialogOverlay);
    document.body.classList.remove("dialog-open");
  };
  const closeAfterUpdatingDialogBox = () => {
    const dialogBox = document.querySelector("#dialog-box");
    const dialogOverlay = document.querySelector(".dialog-overlay");
    dialogBox.parentNode.removeChild(dialogBox);
    dialogOverlay.parentNode.removeChild(dialogOverlay);
    document.body.classList.remove("dialog-open");
    window.location.href = fullUrlWithToken;
  };

  const handleShowOnWebChange = (id, checked) => {
    setShowOnWebChecked((prevState) => ({
      ...prevState,
      [id]: checked,
    }));

    if (!checked) {
      setNewlyUncheckedIds((prevState) => [...prevState, id]);
    } else {
      setNewlyUncheckedIds((prevState) =>
        prevState.filter((item) => item !== id)
      );
    }
  };

  const handleShowOnWebChangeForChecked = (id, checked) => {
    setShowOnWebChecked((prevState) => ({
      ...prevState,
      [id]: checked,
    }));

    if (checked) {
      setNewlyCheckedIds((prevState) => [...prevState, id]);
    } else {
      setNewlyCheckedIds((prevState) =>
        prevState.filter((item) => item !== id)
      );
    }
  };

  const handleDownloadClick = async (e, fileName, fileExtension, finalImageURL) => {
    e.preventDefault();
    await onClickDownloadVideo(fileName, fileExtension, finalImageURL, (progress) => {
      setDownloadProgress((prev) => ({
        ...prev,
        [finalImageURL]: progress === 100 ? 'Download' : progress,
      }));
    });
  };

   function renderImageListItems(records, downloadProgress, handleDownloadClick) {
    return records.map((user, index) => {
      const {
        FileNo,
        id,
        workOrderID,
        workOrderNumber,
        workOrderLineItemID,
        workOrderLineItemNumber,
        serviceAppointmnetID,
        serviceAppointmentNumber,
        finalImageURL,
        thumbNailURL,
        longitude,
        latitude,
        owner,
        dateOnImage,
        imageUploadedDateTime,
        imageFileExtension,
        fileName,
        fileDescription,
        siteName,
        patchName,
        workOrderLineItemWorkType,
      } = user;

      const validExtensions = ["png", "jpg", "jpeg", "heif", "heic"];
      if (validExtensions.includes(imageFileExtension.toLowerCase())) {
        return (
          <li key={id}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <LazyLoadImage
              effect="blur"
              delayTime={100}
              threshold={500}
              // src={finalImageURL}
              src={thumbNailURL ? thumbNailURL : finalImageURL}
              alt={workOrderID}
              onClick={() => {
                setOcrData(
                  records.map((blob) => {
                    return blob.ocrData;
                  })
                );
                setServiceAppointment(
                  records.map((blob) => {
                    return blob.serviceAppointmentNumber;
                  })
                );
                setCapturedDate(
                  records.map((blob) => {
                    return blob.dateOnImage;
                  })
                );
                // setDbInsertedDateTime(
                //   records.map((blob) => {
                //     return blob.dbInsertedDateTime;
                //   })
                // );
                setDateTime(
                  records.map((blob) => {
                    return blob.imageUploadedDateTime;
                  })
                );
                setLat(
                  records.map((blob) => {
                    return blob.latitude;
                  })
                );
                setLong(
                  records.map((blob) => {
                    return blob.longitude;
                  })
                );
                setUploadedBy(
                  records.map((blob) => {
                    return blob.owner;
                  })
                );
                setOrganization(
                  records.map((blob) => {
                    return blob.ownerOrganization;
                  })
                );
                setPhotoIndex(records.indexOf(user));
                setIsOpenImage(true);
                setBlobUrls(
                  records.map((blob) => {
                    return blob.finalImageURL;
                  })
                );
                setFiledesc(
                  records.map((blob) => {
                    return blob.fileDescription;
                  })
                );
              }}
            />

            <p style={{ float: "left" }} className="imgInfo">
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Captured Date:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {dateOnImage}
              <br />
              {/* <b>Upload Date/Time to AMS:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {dbInsertedDateTime}
              <br /> */}
              <b>Gps Loc. :</b>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {(longitude == null ? "0.00" : longitude) +
                "\xa0\xa0\xa0" +
                (latitude == null ? "0.00" : latitude)}
              <br />
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename: </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      }
      const videoExtensions = ["mp4", "avi", "hevc", "wmv", "mov"];
      if (videoExtensions.includes(imageFileExtension)) {
        return (
          <Tippy content={
            <>
              <strong>Note:</strong> The download time may vary based on your internet connection 
              speed, specially for larger files. Please avoid refreshing the page while waiting.
            </>
          } placement="bottom" theme="custom">
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            {/* <a className="buttonVideo" href={finalImageURL} target="_blank"></a> */}
            
              <a className="buttonVideo" href={finalImageURL} onClick={(e) =>
               handleDownloadClick(e, fileName, imageFileExtension, finalImageURL)}>

              {downloadProgress[finalImageURL] === undefined  ||
               downloadProgress[finalImageURL] === 'Download' ||
               downloadProgress[finalImageURL] === 100 ? 'Download' : `${downloadProgress[finalImageURL]}%`}
              </a> 

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
          </Tippy>
        );
      } else if (
        imageFileExtension === "doc" ||
        imageFileExtension === "docx"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonDoc" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (
        imageFileExtension === "xls" ||
        imageFileExtension === "xlsx" ||
        imageFileExtension === "csv"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonXls" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (
        imageFileExtension === "ppt" ||
        imageFileExtension === "pptx"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonPpt" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (imageFileExtension === "pdf") {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonPdf" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="button" href={finalImageURL} target="_blank"></a>
            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      }
    });
  }
  function renderImageListItemsForShowOnWeb(records, downloadProgress, handleDownloadClick) {
    return records.map((user, index) => {
      const {
        FileNo,
        id,
        workOrderID,
        workOrderNumber,
        workOrderLineItemID,
        workOrderLineItemNumber,
        serviceAppointmnetID,
        serviceAppointmentNumber,
        finalImageURL,
        thumbNailURL,
        longitude,
        latitude,
        owner,
        dateOnImage,
        // dbInsertedDateTime,
        imageFileExtension,
        fileName,
        fileDescription,
        showOnWeb,
        siteName,
        patchName,
        workOrderLineItemWorkType,
      } = user;
      const validExtensions = ["png", "jpg", "jpeg", "heif", "heic"];
      if (validExtensions.includes(imageFileExtension.toLowerCase())) {
      // if (
      //   imageFileExtension === "png" ||
      //   imageFileExtension === "jpg" ||
      //   imageFileExtension === "jpeg" ||
      //   imageFileExtension === "heif" ||
      //   imageFileExtension === "heic"
      // ) {
        return (
          <li key={id}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <LazyLoadImage
              effect="blur"
              delayTime={100}
              threshold={500}
              src={thumbNailURL ? thumbNailURL : finalImageURL}
              alt={workOrderID}
              onClick={() => {
                setOcrData(
                  records.map((blob) => {
                    return blob.ocrData;
                  })
                );
                setServiceAppointment(
                  records.map((blob) => {
                    return blob.serviceAppointmentNumber;
                  })
                );
                setCapturedDate(
                  records.map((blob) => {
                    return blob.dateOnImage;
                  })
                );
                // setDbInsertedDateTime(
                //   records.map((blob) => {
                //     return blob.dbInsertedDateTime;
                //   })
                // );
                setDateTime(
                  records.map((blob) => {
                    return blob.imageUploadedDateTime;
                  })
                );
                setLat(
                  records.map((blob) => {
                    return blob.latitude;
                  })
                );
                setLong(
                  records.map((blob) => {
                    return blob.longitude;
                  })
                );
                setUploadedBy(
                  records.map((blob) => {
                    return blob.owner;
                  })
                );
                setOrganization(
                  records.map((blob) => {
                    return blob.ownerOrganization;
                  })
                );
                setPhotoIndex(records.indexOf(user));
                setIsOpenImage(true);
                setBlobUrls(
                  records.map((blob) => {
                    return blob.finalImageURL;
                  })
                );
                setFiledesc(
                  records.map((blob) => {
                    return blob.fileDescription;
                  })
                );
              }}
            />
            <p style={{ float: "left" }} className="imgInfo">
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {records.indexOf(user) + 1}{startingFileNo + index} */}
              {/* {records.indexOf(user) + 1} */}
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Captured Date:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {dateOnImage}
              <br />
              {/* <b>Upload Date/Time to AMS:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {dbInsertedDateTime}
              <br /> */}
              <b>Gps Loc. :</b>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {(longitude == null ? "0.00" : longitude) +
                "\xa0\xa0\xa0" +
                (latitude == null ? "0.00" : latitude)}
              <br />
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename: </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
              {/* Release 4.1 */}
              <br />
              <br />
              {showOnWeb === "true" ? (
                <label className="showOnwebLabel">
                  <input
                    type="checkbox"
                    name={"selectShowOnWebName"}
                    id={id}
                    data-file-name={fileName + "." + imageFileExtension}
                    className="firstShowOnWebbox"
                    checked={showOnWebChecked[id]}
                    onChange={(event) =>
                      handleShowOnWebChange(id, event.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Show on website
                </label>
              ) : showOnWeb === "false" ? (
                <label className="showOnwebLabel">
                  <input
                    type="checkbox"
                    name={"selectShowOnWebName"}
                    id={id}
                    data-file-name={fileName + "." + imageFileExtension}
                    className="firstShowOnWebbox"
                    onChange={(event) =>
                      handleShowOnWebChangeForChecked(id, event.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Show on website
                </label>
              ) : null}
              {/* Release 4.1 */}
            </p>
          </li>
        );
      }
      if (
        imageFileExtension === "mp4" ||
        imageFileExtension === "avi" ||
        imageFileExtension === "hevc" ||
        imageFileExtension === "wmv" ||
        imageFileExtension === "mov"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            {/* <a className="buttonVideo" href={finalImageURL} target="_blank"></a> */}           
            <a className="buttonVideo" href={finalImageURL} 
            onClick={(e) => handleDownloadClick(e, fileName, imageFileExtension, finalImageURL)}
            >
              {downloadProgress[finalImageURL] === undefined  ||
               downloadProgress[finalImageURL] === 'Download' ||
               downloadProgress[finalImageURL] === 100 ? 'Download' : `${downloadProgress[finalImageURL]}%`}
              </a> 
            <p className="docInfo">
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {records.indexOf(user) + 1} */}
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (
        imageFileExtension === "doc" ||
        imageFileExtension === "docx"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonDoc" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (
        imageFileExtension === "xls" ||
        imageFileExtension === "xlsx" ||
        imageFileExtension === "csv"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonXls" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (
        imageFileExtension === "ppt" ||
        imageFileExtension === "pptx"
      ) {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonPpt" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else if (imageFileExtension === "pdf") {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="buttonPdf" href={finalImageURL} target="_blank"></a>

            <p className="docInfo">
              {/* <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {records.indexOf(user) + 1}
              <br /> */}
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      } else {
        return (
          <li className="docs" key={finalImageURL}>
            <div className="check">
              <label className="cont">
                <input
                  key={id}
                  id={id}
                  type="checkbox"
                  className="box"
                  value={finalImageURL}
                  checked={isCheck.includes(id)}
                  onChange={(e) => {
                    handleClick(e);
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      handleAdd(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                      if (downloaded === true) {
                        handleClick(e);
                      }
                    } else {
                      handleRemove(
                        e.target.value +
                          "$" +
                          fileName +
                          "$" +
                          imageFileExtension
                      );
                    }
                  }}
                ></input>
              </label>
            </div>
            <a className="button" href={finalImageURL} target="_blank"></a>
            <p className="docInfo">
              <b>File No.:</b>&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {records.indexOf(user) + 1} */}
              {FileNo}
              <br />
              <b>WO No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderNumber}
              <br />
              {siteName && (
                <>
                  <b>Site name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {siteName}
                  <br />
                </>
              )}
              {patchName && (
                <>
                  <b>Patch name:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {patchName}
                  <br />
                </>
              )}
              {workOrderLineItemWorkType && (
                <>
                  <b>Work type.:</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {workOrderLineItemWorkType}
                  <br />
                </>
              )}
              <b>WOLI No.:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {workOrderLineItemNumber}
              <br />
              {serviceAppointmentNumber && (
                <>
                  <b>SA No :</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {serviceAppointmentNumber}
                  <br />
                </>
              )}
              <b>Field User Name:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {owner}
              <br />
              <b>File Description:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {fileDescription}
              <br />
              <b>Filename:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {fileName}.{imageFileExtension} */}
              {/* {fileName.includes(".")
                ? fileName
                : `${fileName}.${imageFileExtension}`} */}
              {fileName
                ? fileName.includes(".")
                  ? fileName
                  : `${fileName}.${imageFileExtension}`
                : null}
            </p>
          </li>
        );
      }
    });
  }
  function openLightbox() {
    return (
      <Lightbox
        animationOnKeyInput={true}
        clickOutsideToClose={false}
        imageTitle={`SA No. :\u00A0\u00A0${serviceAppointment[photoIndex]}`}
        toolbarButtons={customButtons}
        imageCaption={
          isCaptionVisible
            ? `GPS Loc :\u00A0\u00A0${
                (lat[photoIndex] == null ? "NA" : lat[photoIndex]) +
                "\xa0\xa0\xa0\xa0\xa0" +
                (long[photoIndex] == null ? "" : long[photoIndex])
              }\u00A0;\u00A0
        WO Loc :\u00A0\u00A0${
          (lat[photoIndex] == null ? "NA" : lat[photoIndex]) +
          "\xa0\xa0\xa0\xa0\xa0" +
          (long[photoIndex] == null ? "" : long[photoIndex])
        }\u00A0;\u00A0
        CapturedDate :\u00A0\u00A0${
          capturedDate[photoIndex] == null ? "NA" : capturedDate[photoIndex]
        }\u00A0;\u00A0
        UploadedDate :\u00A0\u00A0${
          dateTime[photoIndex] == null ? "NA" : dateTime[photoIndex]
        }\u00A0;\u00A0
        Uploaded By :\u00A0\u00A0${
          uploadedBy[photoIndex] == null ? "NA" : uploadedBy[photoIndex]
        }\u00A0;\u00A0
        Organization :\u00A0\u00A0${
          organization[photoIndex] == null ? "NA" : organization[photoIndex]
        }\u00A0;\u00A0
        OCR Data :\u00A0\u00A0${ocrData[photoIndex]}\u00A0\u00A0
        File Description :\u00A0\u00A0${
          fileDesc[photoIndex] == null ? "NA" : fileDesc[photoIndex]
        }\u00A0;\u00A0`
            : ``
        }
        mainSrc={blobUrls[photoIndex]}
        mainSrcThumbnail={blobUrls[photoIndex]}
        imagePadding={100}
        nextSrc={blobUrls[(photoIndex + 1) % blobUrls.length]}
        prevSrc={blobUrls[(photoIndex + blobUrls.length - 1) % blobUrls.length]}
        onCloseRequest={() => {
          setIsCaptionVisible(false);
          setIsOpenImage(false);
          return;
        }}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + blobUrls.length - 1) % blobUrls.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % blobUrls.length)
        }
      />
    );
  }

  // Helper function to toggle site expansion state
  //Uncomment this code only when you don't want to close associated patch name or worktype
  // const toggleSiteExpansion = (siteKey) => {
  //   setExpansionState((prevState) => ({
  //     ...prevState,
  //     [siteKey]: {
  //       ...prevState[siteKey],
  //       site: !prevState[siteKey]?.site,
  //     },
  //   }));
  // };
  const toggleSiteExpansion = (siteKey) => {
    setExpansionState((prevState) => {
      const newState = { ...prevState };

      // Toggle the site expansion
      newState[siteKey] = {
        ...newState[siteKey],
        site: !newState[siteKey]?.site,
      };

      // Close all patch expansions when closing the site expansion
      if (!newState[siteKey]?.site) {
        for (const patchKey in newState[siteKey]) {
          if (
            patchKey !== "site" &&
            newState[siteKey].hasOwnProperty(patchKey)
          ) {
            newState[siteKey][patchKey].patch = false;
          }
        }
        for (const workTypeKey in newState[siteKey]) {
          if (
            workTypeKey !== "site" &&
            newState[siteKey].hasOwnProperty(workTypeKey)
          ) {
            newState[siteKey][workTypeKey].workType = false;
          }
        }
      }

      return newState;
    });
  };

  const togglePatchWithoutSiteExpansion = (patchKey) => {
    setExpansionState((prevState) => {
      const newState = { ...prevState };
      newState[patchKey] = {
        ...newState[patchKey],
        patch: !newState[patchKey]?.patch,
      };
      return newState;
    });
  };

  const togglePatchExpansion = (siteKey, patchKey) => {
    setExpansionState((prevState) => ({
      ...prevState,
      [siteKey]: {
        ...prevState[siteKey],
        [patchKey]: {
          ...prevState[siteKey][patchKey],
          patch: !prevState[siteKey]?.[patchKey]?.patch,
        },
      },
    }));
  };

  const toggleWorkTypeExpansion = (siteKey, workTypeKey) => {
    setExpansionState((prevState) => ({
      ...prevState,
      [siteKey]: {
        ...prevState[siteKey],
        [workTypeKey]: {
          ...prevState[siteKey][workTypeKey],
          workType: !prevState[siteKey]?.[workTypeKey]?.workType,
        },
      },
    }));
  };

  useEffect(() => {
    const isAnyNewImageChecked = newlyCheckedIds.length > 0;
    const isAnyOldImageUnchecked = newlyUncheckedIds.length > 0;
    if (isAnyNewImageChecked && !isAnyOldImageUnchecked) {
      setIsSubmitButtonDisabled(true);
    } else if (!isAnyNewImageChecked && !isAnyOldImageUnchecked) {
      setIsSubmitButtonDisabled(false);
    } else if (!isAnyNewImageChecked && isAnyOldImageUnchecked) {
      setIsSubmitButtonDisabled(true);
    }
  }, [showOnWebChecked, newlyUncheckedIds]);

  useEffect(() => {
    getSelectedUsers();
  }, []);

  const PaginationGenUsers = ({
    genUsers,
    itemsPerPage,
    currentPage,
    paginate,
    isCheckAll,
  }) => {
    const maxButtons = 5; // Maximum number of buttons to display at once
    const halfMaxButtons = Math.floor(maxButtons / 2);
    const totalPages = Math.ceil(genUsers.length / itemsPerPage);

    // Calculate the range of pages to display
    const startPage = Math.max(1, currentPage - halfMaxButtons);
    const endPage = Math.min(totalPages, startPage + maxButtons - 1);

    const handleClick = (pageNumber) => () => {
      paginate(pageNumber);
      setIsCheck([]);
      isCheckAll(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <div className="pagination">
        <ul className="page">
          <li>
            <button
              className="color-button"
              onClick={handleClick(currentPage - 1)}
              disabled={currentPage === 1} // Disable if on the first page
            >
              Previous
            </button>
          </li>
          {Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          ).map((pageNumber) => (
            <li
              key={pageNumber}
              className={currentPage === pageNumber ? "active" : ""}
            >
              <button
                className="color-button"
                onClick={handleClick(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li>
            <button
              className="color-button"
              onClick={handleClick(currentPage + 1)}
              disabled={currentPage === totalPages} // Disable if on the last page
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    );
  };
  const PaginationUsers = ({
    extractedData,
    currentPage,
    setCurrentPage,
    isCheckAll,
  }) => {
    const maxButtons = 5; // Maximum number of buttons to display at once
    const halfMaxButtons = Math.floor(maxButtons / 2);

    // Calculate the range of pages to display
    const startPage = Math.max(1, currentPage - halfMaxButtons);
    const endPage = Math.min(extractedData.length, startPage + maxButtons - 1);

    const handleClick = (pageNumber) => () => {
      setCurrentPage(pageNumber);
      setIsCheck([]);
      isCheckAll(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // Update the last serviceAppointmentNumber
      if (currentItemsUsers.length > 0) {
        const lastItem = currentItemsUsers[currentItemsUsers.length - 1];
        // const lastSA = lastItem.data[lastItem.data.length - 1];
      }
    };
    return (
      <div className="pagination">
        <ul className="page">
          <li>
            <button
              className="color-button"
              onClick={handleClick(currentPage - 1)}
              disabled={currentPage === 1} // Disable if on the first page
            >
              Previous
            </button>
          </li>
          {Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          ).map((pageNumber) => (
            <li
              key={pageNumber}
              className={currentPage === pageNumber ? "active" : ""}
            >
              <button
                className="color-button"
                onClick={handleClick(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li>
            <button
              className="color-button"
              onClick={handleClick(currentPage + 1)}
              disabled={currentPage === extractedData.length} // Disable if on the last page
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const pageSize = 250;
  let totalCount = 0;
  let extractedData = [];
  let tempGroup = [];
  let currentWorkOrderLineItemNumber = "";
  let fileNo = 1;
  let lastServiceAppointmentNumber = ""; // Keep track of the last service appointment number

  users.forEach((user) => {
    currentWorkOrderLineItemNumber = user.workOrderLineItemNumber;
    user.data.forEach((subItem) => {
      let subItemCount = subItem.data.length;
      let startIndex = 0; // Track the start index for slicing

      while (subItemCount > 0) {
        if (totalCount + Math.min(subItemCount, pageSize) > pageSize) {
          extractedData.push({
            workOrderLineItemNumber: currentWorkOrderLineItemNumber,
            data: tempGroup,
            ArrayLength: totalCount,
          });
          tempGroup = [];
          totalCount = 0;
        }

        let amountToAdd = Math.min(subItemCount, pageSize - totalCount);
        let dataToAdd = subItem.data
          .slice(startIndex, startIndex + amountToAdd)
          .map((item) => {
            // Check if the serviceAppointmentNumber is different from the last one
            if (
              item.serviceAppointmentNumber !== lastServiceAppointmentNumber
            ) {
              fileNo = 1; // Reset fileNo to 1 only if serviceAppointmentNumber different
              lastServiceAppointmentNumber = item.serviceAppointmentNumber; // Update the last service appointment number
            }
            let newItem = {
              ...item,
              FileNo: fileNo, // Assign the current fileNo
            };
            fileNo++; // Increment fileNo for the next item
            return newItem;
          });

        tempGroup.push({ ...subItem, data: dataToAdd });
        totalCount += amountToAdd;
        subItemCount -= amountToAdd;
        startIndex += amountToAdd; // Update startIndex
      }
    });

    if (tempGroup.length > 0) {
      extractedData.push({
        workOrderLineItemNumber: currentWorkOrderLineItemNumber,
        data: tempGroup,
        ArrayLength: totalCount,
      });
      tempGroup = [];
      totalCount = 0;
      fileNo = 1; // Reset fileNo after pushing to extractedData
    }
  });

  //Removing Woli with No data to prevent blank page.
  extractedData = extractedData.filter((item) => item.data.length > 0);
  const currentItemsUsers = extractedData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  //Array of total id's (250) of sliced data to use Select All button.
  currentItemsUsers.forEach((item) => {
    arrayWoli = arrayWoli.concat(
      item.data.flatMap((innerItem) => innerItem.data)
    );
  });

  //ImgUri of current page
  const ImgUri = arrayWoli.map((user) => {
    // return user.finalImageURL;
    return (
      user.finalImageURL + "$" + user.fileName + "$" + user.imageFileExtension
    );
  });

  // console.log("users: ");
  // console.log(users);
  // console.log("extractedData :");
  // console.log(extractedData);
  // console.log("Sliced extractedData :");
  // console.log(extractedData.slice(indexOfFirstItem, indexOfLastItem));
  // console.log("ImgUri: " + ImgUri.length)
  // console.log("Download Images: " + downloadImages.length)
  // console.log("isCheck " + isCheck.length)
  // console.log("isCheckAll :" + isCheckAll)
  

  if (updateFlag === "true") {
    if (
      saCaseNumber != null ||
      ptwNumber != null ||
      saOrderNumber != null ||
      searchMetaDataKey != null ||
      searchMetaDataValue != null ||
      saAccount != null ||
      saCart != null
    ) {
      return (
        <>
          {errorMessage !== "" ? (
            <section className="errorsec">
              <div className="errMsg">
                <h1 className="mainerror">Access Denied</h1>
                <h3 className="error"> {errorMessage} </h3>
                <button className="btnclosewind clswindow" onClick={onClose}>
                  Close Window
                </button>
              </div>
            </section>
          ) : (
            <>
              {unavailable === true ? (
                <section>
                  <div className="errMsg">
                    <h2 className="mainerror">
                      {/* {ptwNumber ? "Images/Documents for this PTW Number are being processed or has been deleted." 
                      : "Images/Documents for this Salesforce Case Number are being processed or has been deleted."} */}
                      Images/Documents are being processed or has been deleted.
                    </h2>
                    <button
                      className="btnclosewind clswindow"
                      onClick={onClose}
                    >
                      Close Window
                    </button>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="imageGrid">
                    <div className="topInformation">
                      <h3>
                        {ptwNumber
                          ? "PTW Number : " + ptwNumber
                          : saCaseNumber
                          ? "Salesforce Case Number : " + saCaseNumber
                          : saOrderNumber
                          ? "Salesforce Order Number: " + saOrderNumber
                          : searchMetaDataKey
                          ? searchMetaDataKey + " : " + searchMetaDataValue
                          : saCart
                          ? "Salesforce Cart: " + saCart
                          : saAccount
                          ? "Salesforce Account: " + saAccount
                          : null}
                        <br></br>
                        <div className="SelectAll">
                          <label className="firstCont">
                            <input
                              type="checkbox"
                              name="selectAll"
                              id="selectAll"
                              className="firstbox"
                              onChange={genHandleSelectAll}
                              checked={isCheckAll}
                            ></input>
                            {isCheckAll ? "Unselect All" : "Select All"}
                          </label>
                          <button
                            className="btn-download"
                            onClick={() => {
                              if (isCheckAll) {
                                genImgUri.forEach(function (e) {
                                  var fileurl = e.split("$")[0];
                                  var downloadFileName = e.split("$")[1];
                                  var downloadFileNameExtension =
                                    e.split("$")[2];
                                  fetch(fileurl)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      var a = document.createElement("a");
                                      var url = URL.createObjectURL(blob);
                                      a.href = url;
                                      a.download =
                                        downloadFileName +
                                        "." +
                                        downloadFileNameExtension;
                                      a.click();
                                      URL.revokeObjectURL(url);
                                    })
                                    .catch((error) =>
                                      console.error(
                                        "Error downloading attachment:",
                                        error
                                      )
                                    );
                                });
                                setIsCheck([]);
                                setIsCheckAll(false);
                                setDownloadImages([]);
                              } else {
                                downloadImages.forEach(function (e) {
                                  var fileurl = e.split("$")[0];
                                  var downloadFileName = e.split("$")[1];
                                  var downloadFileNameExtension =
                                    e.split("$")[2];
                                  fetch(fileurl)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      var a = document.createElement("a");
                                      var url = URL.createObjectURL(blob);
                                      a.href = url;
                                      a.download =
                                        downloadFileName +
                                        "." +
                                        downloadFileNameExtension;
                                      a.click();
                                      URL.revokeObjectURL(url);
                                    })
                                    .catch((error) =>
                                      console.error(
                                        "Error downloading attachment:",
                                        error
                                      )
                                    );
                                });
                                setIsDownloaded(true);
                                setIsCheck([]);
                                setIsCheckAll(false);
                                setDownloadImages([]);
                              }
                            }}
                          >
                            Download
                          </button>
                          {/* <GenericDownloadButton
                          downloadImages={downloadImages}
                          isCheckAll={isCheckAll}
                          genImgUri={genImgUri}
                          setIsCheck={setIsCheck}
                          setIsCheckAll={setIsCheckAll}
                          setDownloadImages={setDownloadImages}
                          /> */}
                        </div>
                        {/* Release 4.1 */}
                        <div className="submitShowOnWeb">
                          <button
                            className={`submit-flag${
                              isSubmitButtonDisabled ? "" : " disabled"
                            }`}
                            onClick={handleShowOnWebForSFCaseNumberClick}
                            disabled={!isSubmitButtonDisabled}
                          >
                            Submit
                          </button>
                        </div>
                        {/* Release 4.1 */}
                      </h3>
                    </div>
                    <li className="woli">
                      <li>
                        <ol type="1" className="users">
                          {/* {currentItemsGenUsers.map((user) => {  */}
                          {genUsers.map((user) => {
                            const {
                              id,
                              fileName,
                              originalFileName,
                              fileUploadedDateTime,
                              fileExtension,
                              fileSize,
                              sourceSystem,
                              fileURI,
                              fileFinalURL,
                              fileThumbnailURI,
                              metaData,
                              imageAttributes,
                              showOnWeb,
                            } = user;
                            var showFileURL = fileFinalURL;
                            const validExtensions = ["png", "jpg", "jpeg", "heif", "heic"];
                            const checkExtension = fileExtension && fileExtension.includes(".") ? fileExtension.split(".").pop() : fileExtension || "";
                            if (validExtensions.includes(checkExtension.toLowerCase())) {
                            // if (
                            //   showFileURL.split(".").pop() === "png" ||
                            //   showFileURL.split(".").pop() === "jpg" ||
                            //   showFileURL.split(".").pop() === "jpeg" ||
                            //   showFileURL.split(".").pop() === "heif" ||
                            //   showFileURL.split(".").pop() === "heic"
                            // ) {
                              return (
                                <li key={id}>
                                  <div className="check">
                                    <label className="cont">
                                      <input
                                        key={id}
                                        id={id}
                                        type="checkbox"
                                        className="box"
                                        value={
                                          fileFinalURL + genericStorageSasToken
                                        }
                                        checked={isCheck.includes(id)}
                                        onChange={(e) => {
                                          handleClick(e);
                                          const isChecked = e.target.checked;
                                          if (isChecked) {
                                            handleAdd(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                            if (downloaded === true) {
                                              handleClick(e);
                                            }
                                          } else {
                                            handleRemove(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                          }
                                        }}
                                      ></input>
                                    </label>
                                  </div>
                                  <LazyLoadImage
                                    effect="blur"
                                    delayTime={100}
                                    threshold={500}
                                    // src={fileFinalURL}
                                    src={
                                      fileThumbnailURI
                                        ? fileThumbnailURI +
                                          genericStorageSasToken
                                        : fileFinalURL + genericStorageSasToken
                                    }
                                    alt={originalFileName}
                                    onClick={() => {
                                      setOcrData(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].ocrData) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes.ocrData) ||
                                            []
                                          );
                                        })
                                      );
                                      setCapturedDate(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].dateOnImage) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .dateOnImage) ||
                                            []
                                          );
                                        })
                                      );
                                      setDateTime(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          return blob.fileUploadedDateTime;
                                        })
                                      );
                                      setLat(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].coordinates[0].latitude) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .coordinates &&
                                              blob.imageAttributes.coordinates
                                                .latitude) ||
                                            []
                                          );
                                        })
                                      );
                                      setLong(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].coordinates[0].longitude) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .coordinates &&
                                              blob.imageAttributes.coordinates
                                                .longitude) ||
                                            []
                                          );
                                        })
                                      );
                                      // setPhotoIndex(currentItemsGenUsers.indexOf(user));
                                      setPhotoIndex(genUsers.indexOf(user));
                                      setIsOpenImage(true);
                                      setBlobUrls(
                                        // {currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          return (
                                            blob.fileFinalURL +
                                            genericStorageSasToken
                                          );
                                        })
                                      );
                                    }}
                                  />

                                  <p
                                    style={{ float: "left" }}
                                    className="imgInfo"
                                  >
                                    <b>File No.:</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {currentItemsGenUsers.indexOf(user) + 1} */}
                                    {genUsers.indexOf(user) + 1}
                                    <br />
                                    <b>File Name :</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {fileName
                                      ? fileName + "." + fileExtension
                                      : originalFileName} */}

                                    {
                                      fileName
                                        ? fileName.includes(".")
                                          ? fileName
                                          : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                        : originalFileName
                                    }


                                    <br />
                                    <b>Captured Date:</b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {imageAttributes ? (imageAttributes[0].dateOnImage ? imageAttributes[0].dateOnImage : "NA") : "NA"} */}
                                    {imageAttributes
                                      ? imageAttributes.dateOnImage
                                        ? imageAttributes.dateOnImage
                                        : "NA"
                                      : "NA"}
                                    <br />
                                    <b>Gps Loc. :</b>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {imageAttributes &&
                                    imageAttributes.coordinates
                                      ? (imageAttributes.coordinates
                                          .longitude == null
                                          ? "0.00"
                                          : imageAttributes.coordinates
                                              .longitude) +
                                        "\xa0\xa0\xa0" +
                                        (imageAttributes.coordinates.latitude ==
                                        null
                                          ? "0.00"
                                          : imageAttributes.coordinates
                                              .latitude)
                                      : "NA"}
                                    <br />
                                    <b>Source System :</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {sourceSystem}
                                    {/* Release 4.1 */}
                                    <br />
                                    <br />
                                    {showOnWeb === "true" ? (
                                      <label className="showOnwebLabel">
                                        <input
                                          type="checkbox"
                                          name={"selectShowOnWebName"}
                                          id={id}
                                          // data-file-name={
                                          //   fileName
                                          //     ? fileName + "." + fileExtension
                                          //     : originalFileName
                                          // }
                                          data-file-name={
                                            fileName
                                              ? fileName.includes(".")
                                                ? fileName
                                                : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                              : originalFileName
                                          }                                          
                                          className="firstShowOnWebbox"
                                          checked={showOnWebChecked[id]}
                                          onChange={(event) =>
                                            handleShowOnWebChange(
                                              id,
                                              event.target.checked
                                            )
                                          }
                                        />
                                        &nbsp;&nbsp;Show on website
                                      </label>
                                    ) : (
                                      <label className="showOnwebLabel">
                                        <input
                                          type="checkbox"
                                          name={"selectShowOnWebName"}
                                          id={id}
                                          // data-file-name={
                                          //   fileName
                                          //     ? fileName + "." + fileExtension
                                          //     : originalFileName
                                          // }
                                          data-file-name={
                                            fileName
                                              ? fileName.includes(".")
                                                ? fileName
                                                : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                              : originalFileName
                                          }  
                                          className="firstShowOnWebbox"
                                          onChange={(event) =>
                                            handleShowOnWebChangeForChecked(
                                              id,
                                              event.target.checked
                                            )
                                          }
                                        />
                                        &nbsp;&nbsp;Show on website
                                      </label>
                                    )}
                                    {/* Release 4.1 */}
                                  </p>
                                </li>
                              );
                            } else {
                              return (
                                <li className="docs" key={fileFinalURL}>
                                  <div className="check">
                                    <label className="cont">
                                      <input
                                        key={id}
                                        id={id}
                                        type="checkbox"
                                        className="box"
                                        value={
                                          fileFinalURL + genericStorageSasToken
                                        }
                                        checked={isCheck.includes(id)}
                                        onChange={(e) => {
                                          handleClick(e);
                                          const isChecked = e.target.checked;
                                          if (isChecked) {
                                            handleAdd(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                            if (downloaded === true) {
                                              handleClick(e);
                                            }
                                          } else {
                                            handleRemove(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                          }
                                        }}
                                      ></input>
                                    </label>
                                  </div>
                                  <LazyLoadImage
                                    effect="blur"
                                    delayTime={100}
                                    threshold={500}
                                    src={
                                      fileThumbnailURI + genericStorageSasToken
                                    }
                                    alt={originalFileName}
                                    onClick={() => {
                                      const newWindow = window.open(
                                        fileFinalURL + genericStorageSasToken,
                                        "_blank"
                                      );
                                      if (newWindow) {
                                        newWindow.history.replaceState(
                                          {},
                                          "",
                                          "?"
                                        );
                                      }
                                    }}
                                  />
                                  <p>
                                    <b>File No.:</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {currentItemsGenUsers.indexOf(user) + 1} */}
                                    {genUsers.indexOf(user) + 1}
                                    <br />
                                    <b>File Name: </b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {fileName
                                      ? fileName + "." + fileExtension
                                      : originalFileName} */}
                                    {
                                      fileName
                                        ? fileName.includes(".")
                                          ? fileName
                                          : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                        : originalFileName
                                    }
                                    <br />
                                    <b>Source System: </b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {sourceSystem}
                                  </p>
                                </li>
                              );
                            }
                          })}
                          {isOpenImage && (
                            <Lightbox
                              animationOnKeyInput={true}
                              clickOutsideToClose={false}
                              // imageTitle={`Salesforce Case No. :\u00A0\u00A0${saCaseNumber}`}
                              imageTitle={
                                ptwNumber
                                  ? "PTW Number : " + ptwNumber
                                  : saCaseNumber
                                  ? "Salesforce Case Number : " + saCaseNumber
                                  : searchMetaDataKey
                                  ? searchMetaDataKey +
                                    " : " +
                                    searchMetaDataValue
                                  : saOrderNumber
                                  ? "Salesforce Order Number: " + saOrderNumber
                                  : saCart
                                  ? "Salesforce Cart: " + saCart
                                  : saAccount
                                  ? "Salesforce Account: " + saAccount
                                  : null
                              }
                              toolbarButtons={customButtons}
                              imageCaption={
                                isCaptionVisible
                                  ? `GPS Loc :\u00A0\u00A0${
                                      (lat[photoIndex] == null
                                        ? "NA"
                                        : lat[photoIndex]) +
                                      "\xa0\xa0\xa0\xa0\xa0" +
                                      (long[photoIndex] == null
                                        ? ""
                                        : long[photoIndex])
                                    }\u00A0;\u00A0                      
                        CapturedDate :\u00A0\u00A0${
                          capturedDate[photoIndex] == null
                            ? "NA"
                            : capturedDate[photoIndex]
                        }\u00A0;\u00A0
                        UploadedDate :\u00A0\u00A0${
                          dateTime[photoIndex] == null
                            ? "NA"
                            : dateTime[photoIndex]
                        }\u00A0;\u00A0                       
                        OCR Data :\u00A0\u00A0${ocrData[photoIndex]}\u00A0\u00A0
                        `
                                  : ``
                              }
                              mainSrc={blobUrls[photoIndex]}
                              mainSrcThumbnail={blobUrls[photoIndex]}
                              imagePadding={100}
                              nextSrc={
                                blobUrls[(photoIndex + 1) % blobUrls.length]
                              }
                              prevSrc={
                                blobUrls[
                                  (photoIndex + blobUrls.length - 1) %
                                    blobUrls.length
                                ]
                              }
                              onCloseRequest={() => {
                                setIsCaptionVisible(false);
                                setIsOpenImage(false);
                                return;
                              }}
                              onMovePrevRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + blobUrls.length - 1) %
                                    blobUrls.length
                                )
                              }
                              onMoveNextRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + 1) % blobUrls.length
                                )
                              }
                            />
                          )}
                          {/* })} */}
                        </ol>
                      </li>
                    </li>
                    {/* <div>
                    <PaginationGenUsers
                       genUsers={genUsers}
                       itemsPerPage={itemsPerPage}
                       currentPage={currentPage}
                       paginate={paginate}
                       isCheckAll={setIsCheckAll}
                    />
                    </div> */}
                  </div>
                </section>
              )}
            </>
          )}
        </>
      );
    } else {
      if (recordType === "Site" || recordType === "Patch") {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order&nbsp;:&nbsp;&nbsp;{workdIds[0]}
                          <br></br>
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              Select All
                              {/* {isCheckAll ? 'Unselect All' : 'Select All'} */}
                            </label>
                            <button
                              className="btn-download"
                              onClick={() => {
                                if (isCheckAll) {
                                  ImgUri.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });

                                  // ImgUri.forEach(function (e) {
                                  //   var exten = e.split(".").pop();
                                  //   if (
                                  //     exten === "jpg" ||
                                  //     exten === "png" ||
                                  //     exten === "jpeg"
                                  //   ) {
                                  //     // FileSaver.saveAs(e, "image.jpg");
                                  //     FileSaver.saveAs(e, "image.jpg");
                                  //   } else {
                                  //     FileSaver.saveAs(e, `file.${exten}`);
                                  //   }
                                  // });
                                } else {
                                  downloadImages.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                  setIsDownloaded(true);
                                }
                              }}
                            >
                              Download
                            </button>
                          </div>
                          {/* Release 4.1 */}
                          <div className="submitShowOnWeb">
                            <button
                              className={`submit-flag${
                                isSubmitButtonDisabled ? "" : " disabled"
                              }`}
                              onClick={handleShowOnWebClick}
                              disabled={!isSubmitButtonDisabled}
                            >
                              Submit
                            </button>
                          </div>
                          {/* Release 4.1 */}
                        </h3>
                      </div>
                      {Object.entries(usersReinstatement).map(
                        ([siteKey, siteValue]) => {
                          return (
                            <li key={siteKey} className="siteName">
                              {siteKey && (
                                <div className="siteNameBar">
                                  <h4>
                                    <button
                                      className="siteNameButton"
                                      onClick={() =>
                                        toggleSiteExpansion(siteKey)
                                      }
                                    >
                                      {expansionState[siteKey]?.site
                                        ? "-"
                                        : "+"}
                                    </button>
                                    Site name: {siteKey}
                                  </h4>
                                </div>
                              )}
                              {expansionState[siteKey]?.site && (
                                <div className="patchNameBar">
                                  {Object.entries(siteValue).map(
                                    ([patchKey, patchValue]) => {
                                      return (
                                        <div key={patchKey}>
                                          {patchKey && (
                                            <div className="patchNameItem">
                                              <h4>
                                                <button
                                                  className="patchNameButton"
                                                  onClick={() =>
                                                    togglePatchExpansion(
                                                      siteKey,
                                                      patchKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[siteKey]?.[
                                                    patchKey
                                                  ]?.patch
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Patch name: {patchKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[siteKey]?.[patchKey]
                                            ?.patch && (
                                            <li className="woli">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItemsForShowOnWeb(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                          {!patchKey && (
                                            <li className="woliWithoutPatch">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItemsForShowOnWeb(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                              {!siteKey && (
                                <div className="patchNameBar">
                                  {Object.entries(siteValue).map(
                                    ([patchKey, patchValue]) => {
                                      return (
                                        <div key={patchKey}>
                                          {patchKey && (
                                            <div className="patchNameItem">
                                              <h4>
                                                <button
                                                  className="patchNameButton"
                                                  onClick={() =>
                                                    togglePatchWithoutSiteExpansion(
                                                      patchKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[patchKey]
                                                    ?.patch
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Patch name: {patchKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[patchKey]?.patch && (
                                            <li className="woli">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItemsForShowOnWeb(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                          {!patchKey && (
                                            <li className="woliWithoutPatch">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItemsForShowOnWeb(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                      {isOpenImage && openLightbox()}
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      } else if (recordType === "Reinstatement_Site_WOLI") {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order&nbsp;:&nbsp;&nbsp;{workdIds[0]}
                          <br></br>
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              Select All
                              {/* {isCheckAll ? 'Unselect All' : 'Select All'} */}
                            </label>
                            <button
                              className="btn-download"
                              onClick={() => {
                                if (isCheckAll) {
                                  ImgUri.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });

                                  // ImgUri.forEach(function (e) {
                                  //   var exten = e.split(".").pop();
                                  //   if (
                                  //     exten === "jpg" ||
                                  //     exten === "png" ||
                                  //     exten === "jpeg"
                                  //   ) {
                                  //     // FileSaver.saveAs(e, "image.jpg");
                                  //     FileSaver.saveAs(e, "image.jpg");
                                  //   } else {
                                  //     FileSaver.saveAs(e, `file.${exten}`);
                                  //   }
                                  // });
                                } else {
                                  downloadImages.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                  setIsDownloaded(true);
                                }
                              }}
                            >
                              Download
                            </button>
                          </div>
                          {/* Release 4.1 */}
                          <div className="submitShowOnWeb">
                            <button
                              className={`submit-flag${
                                isSubmitButtonDisabled ? "" : " disabled"
                              }`}
                              onClick={handleShowOnWebClick}
                              disabled={!isSubmitButtonDisabled}
                            >
                              Submit
                            </button>
                          </div>
                          {/* Release 4.1 */}
                        </h3>
                      </div>
                      {Object.entries(usersReinstatement).map(
                        ([siteKey, siteValue]) => {
                          return (
                            <li key={siteKey} className="siteName">
                              <div className="siteNameBar">
                                <h4>
                                  <button
                                    className="siteNameButton"
                                    onClick={() => toggleSiteExpansion(siteKey)}
                                  >
                                    {expansionState[siteKey]?.site ? "-" : "+"}
                                  </button>
                                  Site name: {siteKey}
                                </h4>
                              </div>
                              {expansionState[siteKey]?.site && (
                                <div className="workTypeBar">
                                  {Object.entries(siteValue).map(
                                    ([workTypeKey, workTypeValue]) => {
                                      return (
                                        <div key={workTypeKey}>
                                          {workTypeKey && (
                                            <div className="workTypeItem">
                                              <h4>
                                                <button
                                                  className="workTypeButton"
                                                  onClick={() =>
                                                    toggleWorkTypeExpansion(
                                                      siteKey,
                                                      workTypeKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[siteKey]?.[
                                                    workTypeKey
                                                  ]?.workType
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Work type: {workTypeKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[siteKey]?.[
                                            workTypeKey
                                          ]?.workType && (
                                            <li className="woli">
                                              {Object.entries(
                                                workTypeValue
                                              ).map(([woliKey, woliValue]) => {
                                                return (
                                                  <div>
                                                    <div key={woliKey}>
                                                      <h5 className="workLine">
                                                        WOLI: {woliKey}
                                                      </h5>
                                                    </div>
                                                    {Object.entries(
                                                      woliValue
                                                    ).map(
                                                      ([saKey, saValue]) => {
                                                        //{woliValue.map((item) => (
                                                        return (
                                                          <div key={saKey}>
                                                            <h6 className="sa">
                                                              SA: {saKey}
                                                            </h6>
                                                            <ol
                                                              type="1"
                                                              className="users"
                                                            >
                                                              {renderImageListItemsForShowOnWeb(
                                                                saValue
                                                              )}
                                                            </ol>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </li>
                                          )}
                                          {!workTypeKey && (
                                            <li className="woliWithoutWorkType">
                                              {Object.entries(
                                                workTypeValue
                                              ).map(([woliKey, woliValue]) => {
                                                return (
                                                  <div>
                                                    <div key={woliKey}>
                                                      <h5 className="workLine">
                                                        WOLI: {woliKey}
                                                      </h5>
                                                    </div>
                                                    {Object.entries(
                                                      woliValue
                                                    ).map(
                                                      ([saKey, saValue]) => {
                                                        //{woliValue.map((item) => (
                                                        return (
                                                          <div key={saKey}>
                                                            <h6 className="sa">
                                                              SA: {saKey}
                                                            </h6>
                                                            <ol
                                                              type="1"
                                                              className="users"
                                                            >
                                                              {renderImageListItemsForShowOnWeb(
                                                                saValue
                                                              )}
                                                            </ol>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                      {isOpenImage && openLightbox()}
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order&nbsp;:&nbsp;&nbsp;{workdIds[0]}
                          <br></br>
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              {isCheckAll ? "Unselect All" : "Select All"}
                            </label>
                            <DownloadButton
                              isCheckAll={isCheckAll}
                              setIsCheckAll={setIsCheckAll}
                              isCheck={isCheck}
                              setIsCheck={setIsCheck}
                              ImgUri={ImgUri}
                              downloadImages={downloadImages}
                              wo={workdIds[0]}
                              totalCount={arrayWoli.length}
                              setDownloadImages={setDownloadImages}
                              createDialogBox={createDialogBox}
                              closeDialogBox={closeDialogBox}
                              accessToken={accessToken}
                            />
                            {/* Code for Exporting metadata into excel */}
                            {/* <DownloadExcelButton
                             currentItemsUsers={currentItemsUsers}
                            /> */}
                          </div>
                          {/* Release 4.1 */}
                          <div className="submitShowOnWeb">
                            <button
                              className={`submit-flag${
                                isSubmitButtonDisabled ? "" : " disabled"
                              }`}
                              onClick={handleShowOnWebClick}
                              disabled={!isSubmitButtonDisabled}
                            >
                              Submit
                            </button>
                          </div>
                          {/* Release 4.1 */}
                        </h3>
                      </div>
                      <li className="woli">
                        {/* {users.map((woli) => ( */}
                        {currentItemsUsers.map((woli) => (
                          <li>
                            <h5 className="workLine">
                              WOLI&nbsp;:&nbsp;&nbsp;&nbsp;
                              {woli.workOrderLineItemNumber}
                            </h5>
                            <p></p>
                            {woli.data.map((sa) => (
                              <li>
                                <h6 className="sa">
                                  SA No.&nbsp;:&nbsp;&nbsp;&nbsp;
                                  {sa.serviceAppointmentNumber}
                                </h6>
                                <br></br>
                                <ol type="1" className="users">
                                  {renderImageListItemsForShowOnWeb(sa.data, downloadProgress, handleDownloadClick)}
                                </ol>
                              </li>
                            ))}
                          </li>
                        ))}
                        {isOpenImage && openLightbox()}
                        <PaginationUsers
                          extractedData={extractedData}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          isCheckAll={setIsCheckAll}
                        />
                      </li>
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      }
    }
  } else {
    if (
      saCaseNumber != null ||
      ptwNumber != null ||
      saOrderNumber != null ||
      searchMetaDataKey != null ||
      searchMetaDataValue != null ||
      saAccount != null ||
      saCart != null
    ) {
      return (
        <>
          {errorMessage !== "" ? (
            <section className="errorsec">
              <div className="errMsg">
                <h1 className="mainerror">Access Denied</h1>
                <h3 className="error"> {errorMessage} </h3>
                <button className="btnclosewind clswindow" onClick={onClose}>
                  Close Window
                </button>
              </div>
            </section>
          ) : (
            <>
              {unavailable === true ? (
                <section>
                  <div className="errMsg">
                    <h2 className="mainerror">
                      {/* {ptwNumber ? "Images/Documents for this PTW Number are being processed or has been deleted." 
                      : "Images/Documents for this Salesforce Case Number are being processed or has been deleted."} */}
                      Images/Documents are being processed or has been deleted.
                    </h2>
                    <button
                      className="btnclosewind clswindow"
                      onClick={onClose}
                    >
                      Close Window
                    </button>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="imageGrid">
                    <div className="topInformation">
                      <h3>
                        {ptwNumber
                          ? "PTW Number : " + ptwNumber
                          : saCaseNumber
                          ? "Salesforce Case Number : " + saCaseNumber
                          : searchMetaDataKey
                          ? searchMetaDataKey + " : " + searchMetaDataValue
                          : saOrderNumber
                          ? "Salesforce Order Number: " + saOrderNumber
                          : saCart
                          ? "Salesforce Cart: " + saCart
                          : saAccount
                          ? "Salesforce Account: " + saAccount
                          : null}
                        <br></br>
                        <div className="SelectAll">
                          <label className="firstCont">
                            <input
                              type="checkbox"
                              name="selectAll"
                              id="selectAll"
                              className="firstbox"
                              onChange={genHandleSelectAll}
                              checked={isCheckAll}
                            ></input>
                            {isCheckAll ? "Unselect All" : "Select All"}
                          </label>
                          <button
                            className="btn-download"
                            onClick={() => {
                              if (isCheckAll) {
                                genImgUri.forEach(function (e) {
                                  var fileurl = e.split("$")[0];
                                  var downloadFileName = e.split("$")[1];
                                  var downloadFileNameExtension =
                                    e.split("$")[2];
                                  fetch(fileurl)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      var a = document.createElement("a");
                                      var url = URL.createObjectURL(blob);
                                      a.href = url;
                                      a.download =
                                        downloadFileName +
                                        "." +
                                        downloadFileNameExtension;
                                      a.click();
                                      URL.revokeObjectURL(url);
                                    })
                                    .catch((error) =>
                                      console.error(
                                        "Error downloading attachment:",
                                        error
                                      )
                                    );
                                });
                                setIsCheck([]);
                                setIsCheckAll(false);
                                setDownloadImages([]);
                              } else {
                                downloadImages.forEach(function (e) {
                                  var fileurl = e.split("$")[0];
                                  var downloadFileName = e.split("$")[1];
                                  var downloadFileNameExtension =
                                    e.split("$")[2];
                                  fetch(fileurl)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      var a = document.createElement("a");
                                      var url = URL.createObjectURL(blob);
                                      a.href = url;
                                      a.download =
                                        downloadFileName +
                                        "." +
                                        downloadFileNameExtension;
                                      a.click();
                                      URL.revokeObjectURL(url);
                                    })
                                    .catch((error) =>
                                      console.error(
                                        "Error downloading attachment:",
                                        error
                                      )
                                    );
                                });
                                setIsDownloaded(true);
                                setIsCheck([]);
                                setIsCheckAll(false);
                                setDownloadImages([]);
                              }
                            }}
                          >
                            Download
                          </button>
                        </div>
                      </h3>
                    </div>
                    <li className="woli">
                      <li>
                        <ol type="1" className="users">
                          {/* {currentItemsGenUsers.map((user) => { */}
                          {genUsers.map((user) => {
                            const {
                              id,
                              fileName,
                              originalFileName,
                              fileUploadedDateTime,
                              fileExtension,
                              fileSize,
                              sourceSystem,
                              fileURI,
                              fileFinalURL,
                              fileThumbnailURI,
                              metaData,
                              imageAttributes,
                            } = user;
                            var showFileURL = fileFinalURL;
                            const validExtensions = ["png", "jpg", "jpeg", "heif", "heic"];
                            const checkExtension = fileExtension && fileExtension.includes(".") ? fileExtension.split(".").pop() : fileExtension || "";
                            if (validExtensions.includes(checkExtension.toLowerCase())) {
                            // if (
                            //   showFileURL.split(".").pop() === "png" ||
                            //   showFileURL.split(".").pop() === "jpg" ||
                            //   showFileURL.split(".").pop() === "jpeg" ||
                            //   showFileURL.split(".").pop() === "heif" ||
                            //   showFileURL.split(".").pop() === "heic"
                            // ) {
                              return (
                                <li key={id}>
                                  <div className="check">
                                    <label className="cont">
                                      <input
                                        key={id}
                                        id={id}
                                        type="checkbox"
                                        className="box"
                                        value={
                                          fileFinalURL + genericStorageSasToken
                                        }
                                        checked={isCheck.includes(id)}
                                        onChange={(e) => {
                                          handleClick(e);
                                          const isChecked = e.target.checked;
                                          if (isChecked) {
                                            handleAdd(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                            if (downloaded === true) {
                                              handleClick(e);
                                            }
                                          } else {
                                            handleRemove(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                          }
                                        }}
                                      ></input>
                                    </label>
                                  </div>
                                  <LazyLoadImage
                                    effect="blur"
                                    delayTime={100}
                                    threshold={500}
                                    // src={fileFinalURL}
                                    src={
                                      fileThumbnailURI
                                        ? fileThumbnailURI +
                                          genericStorageSasToken
                                        : fileFinalURL + genericStorageSasToken
                                    }
                                    alt={originalFileName}
                                    onClick={() => {
                                      setOcrData(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].ocrData) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes.ocrData) ||
                                            []
                                          );
                                        })
                                      );
                                      setCapturedDate(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].dateOnImage) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .dateOnImage) ||
                                            []
                                          );
                                        })
                                      );
                                      setDateTime(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          return blob.fileUploadedDateTime;
                                        })
                                      );
                                      setLat(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].coordinates[0].latitude) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .coordinates &&
                                              blob.imageAttributes.coordinates
                                                .latitude) ||
                                            []
                                          );
                                        })
                                      );
                                      setLong(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          // return (blob.imageAttributes && blob.imageAttributes[0].coordinates[0].longitude) || [];
                                          return (
                                            (blob.imageAttributes &&
                                              blob.imageAttributes
                                                .coordinates &&
                                              blob.imageAttributes.coordinates
                                                .longitude) ||
                                            []
                                          );
                                        })
                                      );
                                      // setPhotoIndex(currentItemsGenUsers.indexOf(user));
                                      setPhotoIndex(genUsers.indexOf(user));
                                      setIsOpenImage(true);
                                      setBlobUrls(
                                        // currentItemsGenUsers.map((blob) => {
                                        genUsers.map((blob) => {
                                          return (
                                            blob.fileFinalURL +
                                            genericStorageSasToken
                                          );
                                        })
                                      );
                                    }}
                                  />

                                  <p
                                    style={{ float: "left" }}
                                    className="imgInfo"
                                  >
                                    <b>File No.:</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {currentItemsGenUsers.indexOf(user) + 1} */}
                                    {genUsers.indexOf(user) + 1}
                                    <br />
                                    <b>File Name :</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {fileName
                                      ? fileName + "." + fileExtension
                                      : originalFileName} */}
                                    {
                                      fileName
                                        ? fileName.includes(".")
                                          ? fileName
                                          : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                        : originalFileName
                                    }
                                    <br />
                                    <b>Captured Date:</b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {imageAttributes ? (imageAttributes[0].dateOnImage ? imageAttributes[0].dateOnImage : "NA") : "NA"} */}
                                    {imageAttributes
                                      ? imageAttributes.dateOnImage
                                        ? imageAttributes.dateOnImage
                                        : "NA"
                                      : "NA"}
                                    <br />
                                    <b>Gps Loc. :</b>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {imageAttributes ?
                                    (imageAttributes[0].coordinates[0].longitude == null
                                      ? "0.00"
                                      : imageAttributes[0].coordinates[0].longitude) +
                                      "\xa0\xa0\xa0" +
                                      (imageAttributes[0].coordinates[0].latitude == null
                                      ? "0.00"
                                      : imageAttributes[0].coordinates[0].latitude) : "NA"} */}
                                    {imageAttributes &&
                                    imageAttributes.coordinates
                                      ? (imageAttributes.coordinates
                                          .longitude == null
                                          ? "0.00"
                                          : imageAttributes.coordinates
                                              .longitude) +
                                        "\xa0\xa0\xa0" +
                                        (imageAttributes.coordinates.latitude ==
                                        null
                                          ? "0.00"
                                          : imageAttributes.coordinates
                                              .latitude)
                                      : "NA"}
                                    <br />
                                    <b>Source System :</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {sourceSystem}
                                    <br />
                                  </p>
                                </li>
                              );
                            } else {
                              return (
                                <li className="docs" key={fileFinalURL}>
                                  <div className="check">
                                    <label className="cont">
                                      <input
                                        key={id}
                                        id={id}
                                        type="checkbox"
                                        className="box"
                                        value={
                                          fileFinalURL + genericStorageSasToken
                                        }
                                        checked={isCheck.includes(id)}
                                        onChange={(e) => {
                                          handleClick(e);
                                          const isChecked = e.target.checked;
                                          if (isChecked) {
                                            handleAdd(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                            if (downloaded === true) {
                                              handleClick(e);
                                            }
                                          } else {
                                            handleRemove(
                                              e.target.value +
                                                "$" +
                                                fileName +
                                                "$" +
                                                fileExtension
                                            );
                                          }
                                        }}
                                      ></input>
                                    </label>
                                  </div>
                                  <LazyLoadImage
                                    effect="blur"
                                    delayTime={100}
                                    threshold={500}
                                    src={
                                      fileThumbnailURI + genericStorageSasToken
                                    }
                                    alt={originalFileName}
                                    onClick={() => {
                                      const newWindow = window.open(
                                        fileFinalURL + genericStorageSasToken,
                                        "_blank"
                                      );
                                      if (newWindow) {
                                        newWindow.history.replaceState(
                                          {},
                                          "",
                                          "?"
                                        );
                                      }
                                    }}
                                  />
                                  <p>
                                    <b>File No.:</b>&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {currentItemsGenUsers.indexOf(user) + 1} */}
                                    {genUsers.indexOf(user) + 1}
                                    <br />
                                    <b>File Name: </b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {fileName
                                      ? fileName + "." + fileExtension
                                      : originalFileName} */}
                                    {
                                      fileName
                                        ? fileName.includes(".")
                                          ? fileName
                                          : fileName + (fileExtension.includes(".") ? fileExtension : "." + fileExtension)
                                        : originalFileName
                                    }
                                    <br />
                                    <b>Source System: </b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {sourceSystem}
                                  </p>
                                </li>
                              );
                            }
                          })}
                          {isOpenImage && (
                            <Lightbox
                              animationOnKeyInput={true}
                              clickOutsideToClose={false}
                              // imageTitle={`Salesforce Case No. :\u00A0\u00A0${saCaseNumber}`}
                              imageTitle={
                                ptwNumber
                                  ? "PTW Number : " + ptwNumber
                                  : saCaseNumber
                                  ? "Salesforce Case Number : " + saCaseNumber
                                  : searchMetaDataKey
                                  ? searchMetaDataKey +
                                    " : " +
                                    searchMetaDataValue
                                  : saOrderNumber
                                  ? "Salesforce Order Number: " + saOrderNumber
                                  : saCart
                                  ? "Salesforce Cart: " + saCart
                                  : saAccount
                                  ? "Salesforce Account: " + saAccount
                                  : null
                              }
                              toolbarButtons={customButtons}
                              imageCaption={
                                isCaptionVisible
                                  ? `GPS Loc :\u00A0\u00A0${
                                      (lat[photoIndex] == null
                                        ? "NA"
                                        : lat[photoIndex]) +
                                      "\xa0\xa0\xa0\xa0\xa0" +
                                      (long[photoIndex] == null
                                        ? ""
                                        : long[photoIndex])
                                    }\u00A0;\u00A0                      
                        CapturedDate :\u00A0\u00A0${
                          capturedDate[photoIndex] == null
                            ? "NA"
                            : capturedDate[photoIndex]
                        }\u00A0;\u00A0
                        UploadedDate :\u00A0\u00A0${
                          dateTime[photoIndex] == null
                            ? "NA"
                            : dateTime[photoIndex]
                        }\u00A0;\u00A0                       
                        OCR Data :\u00A0\u00A0${ocrData[photoIndex]}\u00A0\u00A0
                        `
                                  : ``
                              }
                              mainSrc={blobUrls[photoIndex]}
                              mainSrcThumbnail={blobUrls[photoIndex]}
                              imagePadding={100}
                              nextSrc={
                                blobUrls[(photoIndex + 1) % blobUrls.length]
                              }
                              prevSrc={
                                blobUrls[
                                  (photoIndex + blobUrls.length - 1) %
                                    blobUrls.length
                                ]
                              }
                              onCloseRequest={() => {
                                setIsCaptionVisible(false);
                                setIsOpenImage(false);
                                return;
                              }}
                              onMovePrevRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + blobUrls.length - 1) %
                                    blobUrls.length
                                )
                              }
                              onMoveNextRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + 1) % blobUrls.length
                                )
                              }
                            />
                          )}
                        </ol>
                      </li>
                    </li>
                    {/* <div>
                    <PaginationGenUsers
                       genUsers={genUsers}
                       itemsPerPage={itemsPerPage}
                       currentPage={currentPage}
                       paginate={paginate}
                       isCheckAll={setIsCheckAll}
                    />
                    </div>*/}
                  </div>
                </section>
              )}
            </>
          )}
        </>
      );
    } else {
      if (recordType === "Site" || recordType === "Patch") {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order: {workdIds[0]}
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              Select All
                              {/* {isCheckAll ? 'Unselect All' : 'Select All'} */}
                            </label>
                            <button
                              className="btn-download"
                              onClick={() => {
                                if (isCheckAll) {
                                  ImgUri.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                } else {
                                  downloadImages.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                  setIsDownloaded(true);
                                }
                              }}
                            >
                              Download
                            </button>
                          </div>
                        </h3>
                      </div>
                      {Object.entries(usersReinstatement).map(
                        ([siteKey, siteValue]) => {
                          return (
                            <li key={siteKey} className="siteName">
                              {siteKey && (
                                <div className="siteNameBar">
                                  <h4>
                                    <button
                                      className="siteNameButton"
                                      onClick={() =>
                                        toggleSiteExpansion(siteKey)
                                      }
                                    >
                                      {expansionState[siteKey]?.site
                                        ? "-"
                                        : "+"}
                                    </button>
                                    Site name: {siteKey}
                                  </h4>
                                </div>
                              )}
                              {expansionState[siteKey]?.site && (
                                <div className="patchNameBar">
                                  {Object.entries(siteValue).map(
                                    ([patchKey, patchValue]) => {
                                      return (
                                        <div key={patchKey}>
                                          {patchKey && (
                                            <div className="patchNameItem">
                                              <h4>
                                                <button
                                                  className="patchNameButton"
                                                  onClick={() =>
                                                    togglePatchExpansion(
                                                      siteKey,
                                                      patchKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[siteKey]?.[
                                                    patchKey
                                                  ]?.patch
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Patch name: {patchKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[siteKey]?.[patchKey]
                                            ?.patch && (
                                            <li className="woli">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItems(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                          {!patchKey && (
                                            <li className="woliWithoutPatch">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItems(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                              {!siteKey && (
                                <div className="patchNameBar">
                                  {Object.entries(siteValue).map(
                                    ([patchKey, patchValue]) => {
                                      return (
                                        <div key={patchKey}>
                                          {patchKey && (
                                            <div className="patchNameItem">
                                              <h4>
                                                <button
                                                  className="patchNameButton"
                                                  onClick={() =>
                                                    togglePatchWithoutSiteExpansion(
                                                      patchKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[patchKey]
                                                    ?.patch
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Patch name: {patchKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[patchKey]?.patch && (
                                            <li className="woli">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItems(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                          {!patchKey && (
                                            <li className="woliWithoutPatch">
                                              {Object.entries(patchValue).map(
                                                ([
                                                  workTypeKey,
                                                  workTypeValue,
                                                ]) => {
                                                  return Object.entries(
                                                    workTypeValue
                                                  ).map(
                                                    ([woliKey, woliValue]) => {
                                                      return (
                                                        <div>
                                                          <div key={woliKey}>
                                                            <h5 className="workLine">
                                                              Work type:{" "}
                                                              {workTypeKey}
                                                            </h5>
                                                            <h5 className="workLine">
                                                              WOLI: {woliKey}
                                                            </h5>
                                                          </div>
                                                          {Object.entries(
                                                            woliValue
                                                          ).map(
                                                            ([
                                                              saKey,
                                                              saValue,
                                                            ]) => {
                                                              //{woliValue.map((item) => (
                                                              return (
                                                                <div
                                                                  key={saKey}
                                                                >
                                                                  <h6 className="sa">
                                                                    SA: {saKey}
                                                                  </h6>
                                                                  <ol
                                                                    type="1"
                                                                    className="users"
                                                                  >
                                                                    {renderImageListItems(
                                                                      saValue
                                                                    )}
                                                                  </ol>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              )}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                      {isOpenImage && openLightbox()}
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      } else if (recordType === "Reinstatement_Site_WOLI") {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order: {workdIds[0]}
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              Select All
                              {/* {isCheckAll ? 'Unselect All' : 'Select All'} */}
                            </label>
                            <button
                              className="btn-download"
                              onClick={() => {
                                if (isCheckAll) {
                                  ImgUri.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                } else {
                                  downloadImages.forEach(function (e) {
                                    var fileurl = e.split("$")[0];
                                    var downloadFileName = e.split("$")[1];
                                    var downloadFileNameExtension =
                                      e.split("$")[2];
                                    fetch(fileurl)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        var a = document.createElement("a");
                                        var url = URL.createObjectURL(blob);
                                        a.href = url;
                                        a.download =
                                          downloadFileName +
                                          "." +
                                          downloadFileNameExtension;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                      })
                                      .catch((error) =>
                                        console.error(
                                          "Error downloading attachment:",
                                          error
                                        )
                                      );
                                  });
                                  setIsDownloaded(true);
                                }
                              }}
                            >
                              Download
                            </button>
                          </div>
                        </h3>
                      </div>
                      {Object.entries(usersReinstatement).map(
                        ([siteKey, siteValue]) => {
                          return (
                            <li key={siteKey} className="siteName">
                              <div className="siteNameBar">
                                <h4>
                                  <button
                                    className="siteNameButton"
                                    onClick={() => toggleSiteExpansion(siteKey)}
                                  >
                                    {expansionState[siteKey]?.site ? "-" : "+"}
                                  </button>
                                  Site name: {siteKey}
                                </h4>
                              </div>
                              {expansionState[siteKey]?.site && (
                                <div className="workTypeBar">
                                  {Object.entries(siteValue).map(
                                    ([workTypeKey, workTypeValue]) => {
                                      return (
                                        <div key={workTypeKey}>
                                          {workTypeKey && (
                                            <div className="workTypeItem">
                                              <h4>
                                                <button
                                                  className="workTypeButton"
                                                  onClick={() =>
                                                    toggleWorkTypeExpansion(
                                                      siteKey,
                                                      workTypeKey
                                                    )
                                                  }
                                                >
                                                  {expansionState[siteKey]?.[
                                                    workTypeKey
                                                  ]?.workType
                                                    ? "-"
                                                    : "+"}
                                                </button>
                                                Work type: {workTypeKey}
                                              </h4>
                                            </div>
                                          )}
                                          {expansionState[siteKey]?.[
                                            workTypeKey
                                          ]?.workType && (
                                            <li className="woli">
                                              {Object.entries(
                                                workTypeValue
                                              ).map(([woliKey, woliValue]) => {
                                                return (
                                                  <div>
                                                    <div key={woliKey}>
                                                      <h5 className="workLine">
                                                        WOLI: {woliKey}
                                                      </h5>
                                                    </div>
                                                    {Object.entries(
                                                      woliValue
                                                    ).map(
                                                      ([saKey, saValue]) => {
                                                        return (
                                                          <div key={saKey}>
                                                            <h6 className="sa">
                                                              SA: {saKey}
                                                            </h6>
                                                            <ol
                                                              type="1"
                                                              className="users"
                                                            >
                                                              {renderImageListItems(
                                                                saValue
                                                              )}
                                                            </ol>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </li>
                                          )}
                                          {!workTypeKey && (
                                            <li className="woliWithoutWorkType">
                                              {Object.entries(
                                                workTypeValue
                                              ).map(([woliKey, woliValue]) => {
                                                return (
                                                  <div>
                                                    <div key={woliKey}>
                                                      <h5 className="workLine">
                                                        WOLI: {woliKey}
                                                      </h5>
                                                    </div>
                                                    {Object.entries(
                                                      woliValue
                                                    ).map(
                                                      ([saKey, saValue]) => {
                                                        return (
                                                          <div key={saKey}>
                                                            <h6 className="sa">
                                                              SA: {saKey}
                                                            </h6>
                                                            <ol
                                                              type="1"
                                                              className="users"
                                                            >
                                                              {renderImageListItems(
                                                                saValue
                                                              )}
                                                            </ol>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            </li>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                      {isOpenImage && openLightbox()}
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            {errorMessage !== "" ? (
              <section className="errorsec">
                <div className="errMsg">
                  <h1 className="mainerror">Access Denied</h1>
                  <h3 className="error"> {errorMessage} </h3>
                  <button className="btnclosewind clswindow" onClick={onClose}>
                    Close Window
                  </button>
                </div>
              </section>
            ) : (
              <>
                {unavailable === true ? (
                  <section>
                    <div className="errMsg">
                      <h2 className="mainerror">
                        Images/Documents for this Service Appointment/WOLI are
                        being processed or has been deleted.
                      </h2>
                      <h3 className="error">
                        {
                          "Please use file images option in Salesforce to view the images in the interim"
                        }
                      </h3>
                      <button
                        className="btnclosewind clswindow"
                        onClick={onClose}
                      >
                        Close Window
                      </button>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="imageGrid">
                      <div className="topInformation">
                        <h3>
                          Work Order&nbsp;:&nbsp;&nbsp;{workdIds[0]}
                          <br></br>
                          <div className="SelectAll">
                            <label className="firstCont">
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="firstbox"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                              ></input>
                              {isCheckAll ? "Unselect All" : "Select All"}
                            </label>
                            <DownloadButton
                              isCheckAll={isCheckAll}
                              setIsCheckAll={setIsCheckAll}
                              isCheck={isCheck}
                              setIsCheck={setIsCheck}
                              ImgUri={ImgUri}
                              downloadImages={downloadImages}
                              wo={workdIds[0]}
                              totalCount={arrayWoli.length}
                              setDownloadImages={setDownloadImages}
                              createDialogBox={createDialogBox}
                              closeDialogBox={closeDialogBox}
                              accessToken={accessToken}
                            />
                            {/* Code for Exporting metadata into excel */}
                            {/* <DownloadExcelButton
                             currentItemsUsers={currentItemsUsers}
                            /> */}
                          </div>
                        </h3>
                      </div>
                      <div className="woli">
                        {currentItemsUsers.map((woli) => (
                          <li key={woli.workOrderLineItemNumber}>
                            <h5 className="workLine">
                              WOLI&nbsp;:&nbsp;&nbsp;&nbsp;
                              {woli.workOrderLineItemNumber}
                            </h5>
                            <p></p>
                            {woli.data.map((sa) => (
                              <li key={sa.serviceAppointmentNumber}>
                                <h6 className="sa">
                                  SA No.&nbsp;:&nbsp;&nbsp;&nbsp;
                                  {sa.serviceAppointmentNumber}
                                </h6>
                                <br></br>
                                <ol type="1" className="users">
                                  {renderImageListItems(sa.data, downloadProgress, handleDownloadClick)}
                                </ol>
                              </li>
                            ))}
                          </li>
                        ))}
                        {isOpenImage && openLightbox()}
                        <PaginationUsers
                          extractedData={extractedData}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          isCheckAll={setIsCheckAll}      
                        />
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        );
      }
    }
  }
};
export default Gallery;
