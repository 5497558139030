import axios from "axios";

const onClickDownloadVideo = async (downloadFileName, downloadFileNameExtension, fileurl, setDownloadProgress,createDialogBox,closeDialogBox, retryCount = 3) => {
    try {  
      // createDialogBox("Download", `Downloading Video : ${downloadFileName}.${downloadFileNameExtension}`,[],closeDialogBox);
      const apiurl = process.env.REACT_APP_VIDEO_GETBLOB_API_URL;
      console.log("fileUrl: " + fileurl);
      const blobURLSplit = fileurl.split("/");
      let blobName = blobURLSplit[blobURLSplit.length - 1];
      let fileExtension = downloadFileNameExtension;
  
      const response = await axios.get(apiurl, {
        params: {
          blobName,
          fileExtension
        },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentage = Math.round((current / total) * 100);
          setDownloadProgress(percentage);
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${downloadFileName}.${downloadFileNameExtension}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      if (retryCount > 0) {
        console.log(`Retrying download... Attempts left: ${retryCount}`);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
        return onClickDownloadVideo(downloadFileName, downloadFileNameExtension, fileurl, setDownloadProgress, retryCount - 1);
      } else {
        console.error('All retry attempts failed.');
      }
    }   
  };

  export default onClickDownloadVideo;