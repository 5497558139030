//*************WORKING CODE****************** */

// import axios from "axios";

// const DownloadVideo = async (downloadFileName, downloadFileNameExtension, fileurl,setDownloadProgress ) => {
//   try {
//     const apiurl = process.env.REACT_APP_VIDEO_GETBLOB_API_URL;
//     // const response = await fetch(apiurl, {
//     //   method: 'POST',
//     //   headers: {
//     //     'Content-Type': 'application/json',
//     //   },
//     //   body: JSON.stringify({
//     //     blobName: `${downloadFileName}.${downloadFileNameExtension}`,
//     //     fileExtension: downloadFileNameExtension
//     //   }),
//     // });

//     // const responseData = await response.json();
//     // console.log('API Response:', responseData);
//     // const statusUrl = responseData.statusQueryGetUri;

//     // // Polling for the completion of the orchestration
//     // let result;
//     // while (true) {
//     //   const statusResponse = await fetch(statusUrl);
//     //   result = await statusResponse.json();
//     //   if (result.runtimeStatus === 'Completed') {
//     //     break;
//     //   }
//     //   await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before polling again
//     // }

//     // const binaryData = result.output;
//     // console.log('Binary Data:', binaryData);
//     // const blob = new Blob([new Uint8Array(binaryData)], { type: 'application/octet-stream' });
//     // const url = window.URL.createObjectURL(blob);
//     console.log("fileUrl: " + fileurl);
//     const blobURLSplit = fileurl.split("/");
//     let blobName = blobURLSplit[blobURLSplit.length - 1];
//     let fileExtension = downloadFileNameExtension;

//     const response = await axios.get(apiurl, {
//       params: {
//         blobName,
//         fileExtension
//       },
//       responseType: 'blob',
//       onDownloadProgress: (progressEvent) => {
//         const total = progressEvent.total;
//         const current = progressEvent.loaded;
//         const percentage = Math.round((current / total) * 100);
//         setDownloadProgress(percentage);
//       }
//     });
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = `${downloadFileName}.${downloadFileNameExtension}`;
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//     // updateDownloadCount();
//   } catch (error) {
//     console.error('Error downloading file:', error);
//   }
// };
 
// export default DownloadVideo;


import axios from "axios";

const DownloadVideo = async (downloadFileName, downloadFileNameExtension, fileurl, setDownloadProgress, retryCount = 3) => {
  try {
    const apiurl = process.env.REACT_APP_VIDEO_GETBLOB_API_URL;
    console.log("fileUrl: " + fileurl);
    const blobURLSplit = fileurl.split("/");
    let blobName = blobURLSplit[blobURLSplit.length - 1];
    let fileExtension = downloadFileNameExtension;

    const response = await axios.get(apiurl, {
      params: {
        blobName,
        fileExtension
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const current = progressEvent.loaded;
        const percentage = Math.round((current / total) * 100);
        setDownloadProgress(percentage);
      }
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${downloadFileName}.${downloadFileNameExtension}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
    if (retryCount > 0) {
      console.log(`Retrying download... Attempts left: ${retryCount}`);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      return DownloadVideo(downloadFileName, downloadFileNameExtension, fileurl, setDownloadProgress, retryCount - 1);
    } else {
      console.error('All retry attempts failed.');
    }
  }
};

export default DownloadVideo;
